import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import React from 'react'

import {AddEntry} from './AddEntry'
import {NodeItem} from './data'

export const AddDialog: React.FC<{open: boolean; onClose: () => void; onCreate?: (item: NodeItem) => void}> = ({
  open,
  onClose,
  onCreate,
}) => {
  const handleCreate = (item: NodeItem) => {
    onClose()
    if (onCreate) onCreate(item)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogContent>
        <AddEntry onCreate={handleCreate} autoFocus />
      </DialogContent>
    </Dialog>
  )
}
