import axios from 'axios'

import {deserializeNodeItem, NodeConnection, NodeItem, NodeLink, serializeNodeItem, UnsavedNodeItem} from './data'
import {PersonItem} from './data/person'

const urlBase = ''

export type QueryParameters = {[name: string]: any}

export type QueryFilters = {[field: string]: null | string | number}

export interface LinkPreview {
  title: string
  description: string
  image: string
  url: string
}

export type Action = 'create' | 'update' | 'remove'
export type LinkAction = 'connect' | 'disconnect'
export type RevisionAction = Action | LinkAction
export type LinkRevision = {
  type: LinkAction
  data: {[key: string]: any}
  linkId: string
  fromId: string
  toId: string
  from?: NodeItem
  to?: NodeItem
  by: string
  at: string
}
export type Revision = {
  type: Action
  data: {[key: string]: any}
  objectId: string
  by: string
  at: string
}
export const isLinkRevision = (r: Revision | LinkRevision): r is LinkRevision => Object.keys(r).includes('fromId')

/*
3  CREATE id: 1, node: {title: 'Neue Erfinder', type: 'project'}
1  CREATE id: 2, node: {title: 'Tobi', type: 'person'}
2  CREATE id: 3, node: {title: 'Tom', type: 'person'}
4  UPDATE id: 1, node: {title: 'Neuere Erfinder'}
5  CONNECT from: 2, to: 1, link: {role: 'Project Lead'}
6  CONNECT from: 3, to: 1, link: {role: 'Programmer'}
7  DELETE id: 3
8  -> DISCONNECT from: 3, to: 1, reason: 'revisions/7'
9  CREATE id: 4, node: {title: 'Bob', type: 'person'}
10 DISCONNECT from: 2, to: 1, reason: 'documents/299' ({type: 'text', content: 'New hire to replace'})
11 CONNECT from: 4, to: 1
*/

const query = <T>(query: string, parameters?: QueryParameters): Promise<T> =>
  axios
    .post(
      `${urlBase}/query`,
      {
        query,
        parameters: parameters || {},
      },
      {withCredentials: true},
    )
    .then((res) => res.data as T)

const requestApi = <T>(path: string, body: any): Promise<T> =>
  axios.post(`${urlBase}${path}`, body, {withCredentials: true}).then((res) => res.data as T)

const getList = <T extends NodeItem>(filter: QueryFilters) =>
  requestApi<T[]>('/db/getList', {filter}).then((l) => l.map(deserializeNodeItem))
/*queryList<T>(
    `FOR d IN documents ${filterStringFrom(filters, 'd')}
      RETURN d`,
  )*/

const deserializeConnectionsList = <T extends NodeItem, S extends NodeLink>(
  connections: NodeConnection<T, S>[],
): NodeConnection<T, S>[] => connections.map((c) => ({...c, node: deserializeNodeItem(c.node)}))

const createLink = (data: NodeLink & any): Promise<NodeLink> => {
  const {_from, _to, _id, _key, ...fields} = data
  return requestApi<NodeLink>('/db/connect', {fromId: _from, toId: _to, data: fields})
}
// query<NodeLink[]>('INSERT @data INTO link RETURN NEW', {data}).then(l => l[0])

const handleError = (error: any): void => {
  console.log('ERROR OCCURRED', error)
  alert(error.message || JSON.stringify(error))
}

let cachedSelf: PersonItem | null = null

export const api = {
  handleError,

  self: (): Promise<PersonItem> =>
    cachedSelf
      ? Promise.resolve(cachedSelf)
      : axios
          .get<PersonItem>(`${urlBase}/self`, {withCredentials: true})
          .then((res) => (cachedSelf = deserializeNodeItem(res.data))),

  safeDo: async <T>(
    op: () => Promise<T>,
    {pre, post, onError}: {pre?: () => void; post?: () => void; onError?: (error: any) => void} = {},
  ) => {
    pre && pre()
    try {
      let ret = await op()
      return ret
    } catch (error) {
      if (onError) onError(error)
      else api.handleError(error)
      return Promise.reject(error)
    } finally {
      post && post()
    }
  },

  postIssue: (assignees: string[], requester: string, requestUrl: string, user: string, dueDate: Date | null) =>
    axios
      .post<{url: string}>(
        `${urlBase}/issue`,
        {assignees, requester, requestUrl, user, dueDate: dueDate?.toISOString()},
        {withCredentials: true},
      )
      .then((res) => res.data),

  /**
   * Run a raw query against the database. Be careful to deserialize data that you receive from the database.
   */
  _query: query,

  getRevisionFor: (id: string, populated: boolean) =>
    requestApi<(Revision | LinkRevision)[]>('/db/getRevisionsFor', {id, populated}).then((l) =>
      l.map((c) => {
        if (isLinkRevision(c) && c.from) c.from = deserializeNodeItem(c.from)
        if (isLinkRevision(c) && c.to) c.to = deserializeNodeItem(c.to)
        return c
      }),
    ),

  getList,

  getListByType: <T extends NodeItem>(type: string) => getList<T>({type}),

  getFullTextSearch: (column: string, searchString: string) =>
    requestApi<NodeItem[]>('/db/fullTextSearch', {column, searchString}).then((l) =>
      l.map((c) => deserializeNodeItem(c)),
    ),

  getListByKeys: <T extends NodeItem>(keys: string[]): Promise<T[]> =>
    requestApi<T[]>('/db/getListByKeys', {keys}).then((l) => l.map((c) => deserializeNodeItem(c))),
  /* query<T[]>(`FOR key IN @keys RETURN DOCUMENT("documents", key)`, {
      keys,
    }).then(l => l.map(c => deserializeNodeItem(c))),*/

  getConnectionsByLinkKeys: <T extends NodeItem, S extends NodeLink>(keys: string[]): Promise<NodeConnection<T, S>[]> =>
    requestApi<NodeConnection<T, S>[]>('/db/getConnectionsByLinkKeys', {keys}).then(deserializeConnectionsList),
  /*queryConnections<T, S>(
      `FOR key IN @keys
            RETURN {"node": DOCUMENT("documents", DOCUMENT("link", key)._to), "link": DOCUMENT("link", key)}`,
      {keys},
    ),*/

  getConnectionsFrom: <T extends NodeItem, S extends NodeLink>(
    from: NodeItem | string,
    filter: QueryFilters,
    includeSelf?: boolean,
  ) =>
    requestApi<NodeConnection<T, S>[]>('/db/getConnections', {
      outbound: true,
      fromKey: typeof from === 'string' ? from : from._key,
      filter,
      includeSelf: !!includeSelf,
    }).then(deserializeConnectionsList),
  /*queryConnections<T, S>(
      `FOR v,e in 0..1 OUTBOUND DOCUMENT("documents", @fromId) link ${filterStringFrom(
        filters,
        'e',
      )} RETURN {"node": v, "link": e}`,
      {fromId: from._id},
    ),*/

  getConnectionsTo: <T extends NodeItem, S extends NodeLink>(
    from: NodeItem | string,
    filter: QueryFilters,
    includeSelf?: boolean,
  ) =>
    requestApi<NodeConnection<T, S>[]>('/db/getConnections', {
      outbound: false,
      fromKey: typeof from === 'string' ? from : from._key,
      filter,
      includeSelf: !!includeSelf,
    }).then(deserializeConnectionsList),
  /*queryConnections<T, S>(
      `FOR v,e in 0..1 INBOUND DOCUMENT("documents", @fromId) link ${filterStringFrom(
        filters,
        'e',
      )} RETURN {"node": v, "link": e}`,
      {fromId: from._id},
    ),*/

  // getSingle: <T extends NodeItem>(filters: {[field: string]: string | null | number}) =>
  //   requestApi<T>('/db/getSingle', {filters}).then(deserializeNodeItem),
  // getList<T>(filters).then(l => l[0]),

  /**
   * Query connections of a nodeitem. Requires the return value to be {node: NodeItem, link: NodeLink}[]
   */
  /*queryConnections: <T extends NodeItem, S extends NodeLink>(queryString: string, parameters: QueryParameters) =>
    query<NodeConnection<T, S>[]>(queryString, parameters).then(l =>
      l.map(c => ({...c, node: deserializeNodeItem(c.node)})),
    ),*/

  create: <T extends NodeItem>(data: UnsavedNodeItem): Promise<T> =>
    requestApi<T>('/db/create', serializeNodeItem(data)).then(deserializeNodeItem),
  /* query<NodeItem[]>('INSERT @data INTO documents RETURN NEW', {data: serializeNodeItem(data)}).then(l =>
      deserializeNodeItem(l[0]),
    ),*/

  /**
   * Adds a bookmark to the user's bookmark. Returns an updated copy of the user.
   */
  toggleBookmark: (user: PersonItem, node: NodeItem): Promise<PersonItem> =>
    user.bookmarks.includes(node._key)
      ? query(
          `LET u = DOCUMENT("documents", @user) UPDATE u WITH {bookmarks: REMOVE_VALUE(u.bookmarks, @docKey)} IN documents`,
          {user: user._key, docKey: node._key},
        ).then(() => ({...user, bookmarks: user.bookmarks.filter((b) => b !== node._key)}))
      : query(
          'LET u = DOCUMENT("documents", @user) UPDATE u WITH {bookmarks: PUSH(u.bookmarks, @docKey)} IN documents',
          {user: user._key, docKey: node._key},
        ).then(() => ({...user, bookmarks: [...user.bookmarks, node._key]})),

  createLink,

  link: ({from, to, link}: {from: NodeItem; to: NodeItem; link: {[key: string]: any} & {type: string}}) =>
    createLink({...link, _from: from._id, _to: to._id}),

  deleteLink: (data: NodeLink): Promise<void> => requestApi('/db/disconnect', {key: data._key}),
  // query<void>('REMOVE @key IN link', {key: data._key}),

  deleteNode: (data: NodeItem): Promise<void> => requestApi('/db/remove', {key: data._key}),
  /*query(
      `LET r = (FOR v, e IN 1..1 ANY DOCUMENT('documents', @key) GRAPH 'fored1' REMOVE e._key IN link) REMOVE @key IN documents`,
      {key: data._key},
    ),*/

  deleteNodesPermanently: (keys: string[]): Promise<void> => requestApi('/db/deleteNodesPermanently', {keys}),
  /* query(
      `FOR key IN @keys
        LET r = (FOR v, e IN 1..1 ANY DOCUMENT('documents', key) GRAPH 'fored1' REMOVE e._key IN link) REMOVE key IN documents`,
      {keys},
    ),*/

  updateNode: (item: NodeItem, keys?: string[]): Promise<void> => {
    const {_key, _id, owner, groups, ...serialized} = serializeNodeItem(item)
    return requestApi('/db/update', {
      key: item._key,
      data: keys ? keys.reduce<any>((map, key) => (map[key] = serialized[key]), {}) : serialized,
      collection: 'documents',
    })
    /*return query<void>('UPDATE DOCUMENT(@id) WITH @data IN documents', {
      id: item._id,
      data: ,
    })*/
  },

  decrypt: (secret: string, encrypted: string) =>
    requestApi('/db/decrypt', {
      secret,
      encrypted,
    }),

  encrypt: (secret: string, decrypted: string) =>
    requestApi('/db/encrypt', {
      secret,
      decrypted,
    }),

  updateLink: (item: NodeLink, keys?: string[]): Promise<void> => {
    const {_key, _id, _rev, owner, groups, ...serialized} = item
    return requestApi('/db/update', {
      key: item._key,
      data: keys
        ? keys.reduce<any>((map, key) => {
            map[key] = serialized[key]
            return map
          }, {})
        : serialized,
      collection: 'link',
    })
  },

  fetchAllOfType: <T extends NodeItem>(type: string): Promise<T[]> => getList({type}),

  replace: (id: string, data: NodeItem): Promise<void> =>
    query<void>('REPLACE DOCUMENT(@id) WITH @data IN documents', {
      id,
      data: serializeNodeItem(data),
    }),

  replaceLink: (id: string, data: NodeLink): Promise<void> =>
    query<void>('REPLACE DOCUMENT(@id) WITH @data IN link', {
      id,
      data: data,
    }),

  preview: (url: string): Promise<LinkPreview> =>
    axios.post(`${urlBase}/query`, {url}, {withCredentials: true}).then((res) => res.data as LinkPreview),

  verifyGoogleIdToken: (token: string): Promise<PersonItem> =>
    axios
      .post<PersonItem>(`${urlBase}/google-auth`, {token})
      .then((res) => (cachedSelf = res.data)),

  /*login: (email: string, password: string): Promise<NodeItem> =>
    axios.post(`${urlBase}/login`, {
      email,
      password,
    }, {withCredentials: true}).then(res => res.data as NodeItem),*/

  /*signUp: (email: string, password: string): Promise<PersonItem> =>
    axios
      .post(
        `${urlBase}/adduser`,
        {
          email,
          password,
        },
        {withCredentials: true},
      )
      .then(res => res.data as PersonItem),*/
}
