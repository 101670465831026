import {api} from './Api'
import {PersonItem} from './data/person'

export const googleSlideTemplateType = 'application/x-vnd.google-docs-drawings-page+wrapped'
export const googleSlideImageType = 'application/x-vnd.google-docs-image-clip+wrapped'

export interface Slide {
  presentationId: string
  pageObjectId: string
}

const GoogleDocs = {
  init: () => checkInitialize(),

  signIn: async (): Promise<PersonItem> => {
    await checkInitialize(false)
    const user = await gapi.auth2.getAuthInstance().signIn()
    console.log(user.getAuthResponse().id_token)
    return await api.verifyGoogleIdToken(user.getAuthResponse().id_token)
  },

  signOut: (): Promise<void> => gapi.auth2.getAuthInstance().signOut(),

  createDoc: async (title: string) => {
    await checkInitialize()
    const res = await gapi.client.docs.documents.create({title})
    return `https://docs.google.com/document/d/${res.result.documentId}`
  },

  createSlides: async (title: string) => {
    await checkInitialize()
    const res = await gapi.client.slides.presentations.create({title})
    return `https://docs.google.com/presentation/d/${res.result.presentationId}`
  },

  createSheets: async (title: string) => {
    await checkInitialize()
    const res = await gapi.client.sheets.spreadsheets.create({title})
    return `https://docs.google.com/spreadsheets/d/${res.result.presentationId}`
  },

  getThumbnailForSlide: async (presentationId: string, pageObjectId: string) => {
    await checkInitialize()
    const res = await gapi.client.slides.presentations.pages.getThumbnail({presentationId, pageObjectId})
    console.log(res)
    return res.contentUrl
  },

  insertSlide: async (slide: Slide, presentationId: string) => {
    await checkInitialize()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const request = await gapi.client.slides.presentations.batchUpdate({
      presentationId,
      requests: [{createSlide: {}}],
    })
  },

  copySlide: async (slide: Slide, presentationId: string) => {
    /*await axios.get('https://script.google.com/a/4ed1.com/macros/s/AKfycby6rsQrY4VyGJ8E9CYA-M9q1ziju9Mwbv8TuDOmeKGfgjag3Io/exec', {
            params: {
                srcId: slide.presentationId,
                dstId: presentationId,
                srcPage: 1,
            }
        })*/
    console.log('Copying from', slide, 'to', presentationId)
    window.open(
      'https://script.google.com/a/4ed1.com/macros/s/AKfycby6rsQrY4VyGJ8E9CYA-M9q1ziju9Mwbv8TuDOmeKGfgjag3Io/exec' +
        '?srcId=' +
        encodeURIComponent(slide.presentationId) +
        '&dstId=' +
        encodeURIComponent(presentationId) +
        '&srcPage=' +
        1,
      'Copying slide ...',
      'height=300,width=200',
    )
  },
}

export default GoogleDocs

let initializationPromise: Promise<boolean> | null = null

const checkInitialize = (failOnNotLoggedIn: boolean = true): Promise<boolean> => {
  if (initializationPromise) return initializationPromise

  if (!(window as any).gapi)
    throw new Error('Google services not available - are you sure you have connection to the internet?')

  return (initializationPromise = new Promise((resolve, reject) => {
    gapi.load('client:auth2', () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
          hosted_domain: '4ed1.com',
        })
        .then(() => {
          // gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus)
          const loggedIn = gapi.auth2.getAuthInstance().isSignedIn.get()
          if (failOnNotLoggedIn && !loggedIn) return reject(new Error('Not authenticated'))
          else resolve(loggedIn)
        })
        .catch((err: any) => console.log(err))
    })
  }))
}

declare var gapi: any

const CLIENT_ID = '654870556056-tfebla59m98lh1a5tuji9r6fk8idoneu.apps.googleusercontent.com'
const API_KEY = 'AIzaSyDiyGcyqy4j9HECNR2LM8miXo8MAqK5QLc'

const DISCOVERY_DOCS = [
  'https://docs.googleapis.com/$discovery/rest?version=v1',
  'https://slides.googleapis.com/$discovery/rest?version=v1',
]
const SCOPES = 'https://www.googleapis.com/auth/documents https://www.googleapis.com/auth/presentations'
