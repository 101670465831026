import {faEdit, faLink, faMinus, faPlus, faUnlink, IconDefinition} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {api, isLinkRevision, LinkRevision, Revision, RevisionAction} from 'Api'
import React, {useEffect, useState} from 'react'

export const RevisionLog: React.FC<{id: string}> = ({id}) => {
  const [revisions, setRevisions] = useState<(Revision | LinkRevision)[]>([])

  const iconActionMapping: {[type in RevisionAction]: IconDefinition} = {
    connect: faLink,
    disconnect: faUnlink,
    create: faPlus,
    update: faEdit,
    remove: faMinus,
  }

  useEffect(() => {
    api
      .getRevisionFor(id, true)
      .then((r) => r.reverse())
      .then(setRevisions)
  }, [id])

  return (
    <div>
      {revisions.map((r) => (
        <div>
          <FontAwesomeIcon icon={iconActionMapping[r.type]} />
          &nbsp;
          {isLinkRevision(r)
            ? `${r.type} ${r.data?.type} from ${r?.from?.title} to ${r?.to?.title}`
            : `${r.type} ${JSON.stringify(r.data)}`}
          &nbsp;
          <small>
            ({r.by} - {r.at})
          </small>
        </div>
      ))}
    </div>
  )
}
