import {definition as faUserShield} from '@fortawesome/free-solid-svg-icons/faUserShield'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const tacitKnowledgeTemplate: Template<TacitKnowledgeItem> = {
  label: 'Tacit Knowledge',
  type: 'tacit',
  title: 'Add Tacit Knowledge',
  icon: faUserShield,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Notes', key: 'notes', type: 'string', multiline: true},
    {label: 'Taught by', key: 'teaches', type: 'link'},
  ],
}
export default tacitKnowledgeTemplate

export interface TacitKnowledgeItem extends NodeItem {
  notes: string
}
