import {faProjectDiagram} from '@fortawesome/free-solid-svg-icons'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const projectTemplate: Template<ProjectItem> = {
  label: 'Project',
  type: 'project',
  title: 'Add Project',
  icon: faProjectDiagram,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [{label: 'Title', key: 'title', type: 'string'}],
}
export default projectTemplate

export interface ProjectItem extends NodeItem {}
