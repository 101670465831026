import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import React, {useEffect, useState} from 'react'

import {InnerAddEntry} from './AddEntry'
import {api} from './Api'
import {linkTemplateForKey, NodeConnection, NodeItem, NodeLink, Template, templateForType} from './data'
import {LinkTemplate, linkTemplates} from './data/links'
import FilterList from './FilterList'
import Search from './Search'

type Props = {
  node?: NodeItem
  isTarget?: boolean
  selectedLinkType?: string
  onCreate?: (connection: NodeConnection<NodeItem, NodeLink>) => void
  onSelect?: (item: NodeItem) => void
  button: (onClick: (target: HTMLElement, linkType?: string, templateType?: string) => void) => JSX.Element
  showSearch?: boolean
  currentLink?: NodeLink
}

const ConnectButton: React.FC<Props> = ({
  node,
  isTarget,
  onSelect,
  onCreate,
  button,
  selectedLinkType,
  showSearch,
  currentLink,
}) => {
  showSearch = showSearch ?? true
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [linkTemplate, setLinkTemplate] = useState<LinkTemplate | null>(null)
  const [showLinkTemplates, setShowLinkTemplates] = useState(true)
  const [showTemplateFilter, setShowTemplateFilter] = useState(true)
  const [selectedTemplate, setSelectedTemplate] = useState<Template<NodeItem> | null>(null)

  useEffect(() => {
    setLinkTemplate(selectedLinkType ? linkTemplateForKey(selectedLinkType) : null)
  }, [selectedLinkType])

  const handleOpen = (element: HTMLElement, linkType?: string, templateType?: string) => {
    setLinkTemplate(linkType ?? selectedLinkType ? linkTemplateForKey(linkType ?? selectedLinkType!) : null)
    setSelectedTemplate(templateType ? templateForType(templateType) : null)
    setShowLinkTemplates(!linkType)
    setShowTemplateFilter(!templateType)
    setAnchorEl(element)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setLinkTemplate(null)
  }
  const createLinkTo = async (item: NodeItem, template: LinkTemplate) => {
    if (!node) {
      onSelect && onSelect(item)
      return handleClose()
    }

    try {
      if (currentLink) {
        const update = {_to: isTarget ? node._id : item._id, _from: isTarget ? item._id : node._id}
        const updated = {...currentLink, ...update}
        await api.updateLink(updated, ['_to', '_from'])
        onSelect && onSelect(item)
        onCreate && onCreate({link: updated, node: item})
      } else {
        const link = await api.createLink({
          _to: isTarget ? node._id : item._id,
          _from: isTarget ? item._id : node._id,
          type: template.key,
        })
        onSelect && onSelect(item)
        onCreate && onCreate({link: {...link, type: template.key}, node: item})
      }
      handleClose()
    } catch (error) {
      api.handleError(error)
    }
  }

  return (
    <>
      {button(handleOpen)}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{width: '900px'}}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <Grid container wrap="nowrap">
          {showLinkTemplates && (
            <Grid item style={{padding: '1rem'}}>
              <FilterList
                defaultFilterString={linkTemplate ? linkTemplate.label : undefined}
                items={linkTemplates}
                titleCb={(t) => t.label}
                onSelect={setLinkTemplate}
                autoFocus={true}
                keyCb={(t) => t.key}
                iconCb={(t) => t.icon}
                label="Filter ..."
              />
            </Grid>
          )}
          {showSearch && linkTemplate && (
            <Grid item style={{width: '450px', padding: '1rem'}}>
              <Search autoFocus onSelect={(data) => createLinkTo(data, linkTemplate)} />
            </Grid>
          )}
          {linkTemplate && (
            <Grid item style={{width: '450px', padding: '1rem'}}>
              <InnerAddEntry
                hideFilter={!showTemplateFilter}
                onCreate={(data) => createLinkTo(data, linkTemplate)}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            </Grid>
          )}
        </Grid>
      </Popover>
    </>
  )
}
export default ConnectButton
