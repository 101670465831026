import {Typography} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React from 'react'

import {PersonItem} from './data/person'
import GoogleDocs from './GoogleDocs'

export const Login: React.FC<{setUser: React.Dispatch<React.SetStateAction<PersonItem | null>>}> = ({setUser}) => {
  const login = () => GoogleDocs.signIn().then(setUser).catch(alert)

  return (
    <Container style={{maxWidth: '600px', marginTop: '4rem'}}>
      <Typography variant="h3">Forever Day One</Typography>
      <Typography variant="subtitle2">Knowledge Management</Typography>
      <div style={{height: '3rem'}}></div>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button type="submit" onClick={login} variant="outlined">
            Login
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
