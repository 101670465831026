import {faUser} from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {InnerAddEntry} from 'AddEntry'
import {api} from 'Api'
import {User} from 'App'
import {NodeConnection, templateForType, ToolAccessLink, ToolAccessStatus} from 'data'
import {PersonItem} from 'data/person'
import {SecretViewer} from 'data/template_fields'
import {ToolItem} from 'data/tool'
import FilterList from 'FilterList'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {RevisionLog} from 'RevisionLog'

type ChangeRequest = {
  add: ToolAccessLink[]
  del: string[]
  change: {key: string; field: string; value: any}[]
  by: string
  for: string
}
type ExpandedChangeRequest = {
  add: NodeConnection<ToolItem, ToolAccessLink>[]
  del: NodeConnection<ToolItem, ToolAccessLink>[]
  change: {connection: NodeConnection<ToolItem, ToolAccessLink>; field: string; value: any}[]
  by: string
  for: string
}

export const OnboardingPage: React.FC = () => {
  const [users, setUsers] = useState<PersonItem[] | null>(null)
  const [selectedUser, setSelectedUser] = useState<PersonItem | null>(null)
  const [updatedAccesses, setUpdatedAccesses] = useState<NodeConnection<ToolItem, ToolAccessLink>[] | null>(null)
  const [currentAccesses, setCurrentAccesses] = useState<NodeConnection<ToolItem, ToolAccessLink>[] | null>(null)
  const [allTools, setAllTools] = useState<ToolItem[] | null>(null)
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const [hasDueDate, setHasDueDate] = useState(false)
  const [dueDate, setDueDate] = useState<Date>(new Date())
  const [loading, setLoading] = useState(false)
  const [myUser] = useContext(User)

  const setCurrentTools = (t: NodeConnection<ToolItem, ToolAccessLink>[]) => {
    for (const connection of t) {
      if (!connection.link.roles) connection.link.roles = []
    }
    setUpdatedAccesses(t)
    setCurrentAccesses(JSON.parse(JSON.stringify(t)))
  }

  useEffect(() => {
    api
      .safeDo(() => api.fetchAllOfType<PersonItem>('person'))
      .then((users) => {
        setUsers(users)
        setSelectedUser(users.find((u) => u._id === myUser._id) || null)
      })
    api.safeDo(() => api.fetchAllOfType<ToolItem>('tool')).then(setAllTools)
  }, [myUser._id])

  useEffect(() => {
    if (selectedUser)
      api
        .getConnectionsFrom<ToolItem, ToolAccessLink>(selectedUser, {type: 'access_to'})
        .then(setCurrentTools)
    else setUpdatedAccesses([])
  }, [selectedUser])

  const checkUpdateStyles = (tool: ToolItem, property: 'status' | 'roles' | 'notes' | null) => {
    const changedColor = '#eeff00'
    const removedColor = '#ff6633'
    const addedColor = '#66ff33'

    const updated = updatedAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
    const current = currentAccesses!.find((l) => l.node._id === tool._id)?.link ?? null

    if (updated && !current) return {backgroundColor: addedColor}
    if (current && !updated) return {backgroundColor: removedColor}

    if (property && updated && current && JSON.stringify(updated[property]) !== JSON.stringify(current[property]))
      return {backgroundColor: changedColor}

    return {}
  }

  const accessForTool = (tool: ToolItem): ToolAccessLink | null =>
    updatedAccesses!.find((l) => l.node._id === tool._id)?.link ?? null

  const hasAccess = (t: ToolItem) => !!accessForTool(t)

  const computeChangesText = () => {
    let request = ''
    if (!currentAccesses || !updatedAccesses) return request
    for (const tool of allTools ?? []) {
      const updated = updatedAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
      const current = currentAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
      if (updated && !current) request += `Request access to ${tool.title} as ${updated.roles.join(', ')}\n`
      if (!updated && current) request += `Revoke access to ${tool.title}\n`
      if (updated && current && JSON.stringify(updated.roles) !== JSON.stringify(current.roles))
        request += `Change roles for ${tool.title} from "${current.roles.join(', ')}" to "${updated.roles.join(
          ', ',
        )}"\n`
      if (updated && current && updated.notes !== current.notes)
        request += `Change notes for ${tool.title} from "${current.notes ?? ''}" to "${updated.notes}"\n`
    }
    return request
  }
  const changesText = computeChangesText()

  const applyChanges = () => {
    const tasks: Promise<any>[] = []
    if (currentAccesses && updatedAccesses) {
      for (const tool of allTools ?? []) {
        const updated = updatedAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
        const current = currentAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
        if (updated && !current) tasks.push(api.createLink(updated))
        if (!updated && current) tasks.push(api.deleteLink(current))
        if (updated && current) {
          if (JSON.stringify(updated.roles) !== JSON.stringify(current.roles))
            tasks.push(api.updateLink(updated, ['roles']))
          if (updated.notes !== current.notes) tasks.push(api.updateLink(updated, ['notes']))
        }
      }
    }
    return Promise.all(tasks).then(() => {
      setCurrentAccesses(JSON.parse(JSON.stringify(updatedAccesses)))
    })
  }

  const [user] = useContext(User)

  const requestUrl = () => {
    const req: ChangeRequest = {
      for: selectedUser!._key,
      by: user._key,
      add: [],
      del: [],
      change: [],
    }

    if (currentAccesses && updatedAccesses) {
      for (const tool of allTools ?? []) {
        const updated = updatedAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
        const current = currentAccesses!.find((l) => l.node._id === tool._id)?.link ?? null
        if (updated && !current) req.add.push(updated)
        if (!updated && current) req.del.push(current._key)
        if (updated && current && JSON.stringify(updated.roles) !== JSON.stringify(current.roles))
          req.change.push({key: current._key, field: 'roles', value: updated.roles})
        if (updated && current && updated.notes !== current.notes)
          req.change.push({key: current._key, field: 'notes', value: updated.notes})
      }
    }

    return (
      window.location.href.substring(0, window.location.href.length - '/tools'.length) +
      '/apply-tools?req=' +
      encodeURIComponent(JSON.stringify(req))
    )
  }

  const submitRequest = async () => {
    setLoading(true)
    try {
      await api
        .safeDo(() =>
          api.postIssue(['tom@4ed1.com'], user._key, requestUrl(), selectedUser!._key, hasDueDate ? dueDate : null),
        )
        .then(({url}) => window.open(url))
    } finally {
      setLoading(false)
    }
  }

  const padTwo = (n: number) => (n < 10 ? '0' + n : n.toString())
  const formatDate = (date: Date) => `${date.getFullYear()}-${padTwo(date.getMonth() + 1)}-${padTwo(date.getDate())}`

  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '300px', padding: '1rem', flexShrink: 0}}>
        <Button style={{marginBottom: '1rem'}} variant="contained" onClick={() => setAddDialogOpen(true)}>
          Add User
        </Button>
        {users && (
          <FilterList
            items={users}
            onSelect={setSelectedUser}
            label="Filter ..."
            keyCb={(u) => u._id}
            iconCb={(_) => faUser}
            titleCb={(u) => u.title}
            leaveFilterOnSelect
          />
        )}
      </div>
      {selectedUser && (
        <div style={{padding: '1rem', flexGrow: 1}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <a target="_blank" rel="noopener noreferrer" href={'/item/' + selectedUser._key}>
              <h2>{selectedUser.title}</h2>
            </a>
            <div style={{flexGrow: 1}}></div>
            <Button variant="contained" color="secondary">
              Offboard
            </Button>
          </div>
          {updatedAccesses && currentAccesses && allTools && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Access</TableCell>
                    <TableCell>Tool</TableCell>
                    <TableCell>Password</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allTools.map((t) => (
                    <TableRow key={t._id} style={checkUpdateStyles(t, null)}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={hasAccess(t)}
                          onChange={(_) =>
                            setUpdatedAccesses((accesses) => {
                              const key = Math.random().toString().substring(3, 9)
                              return accesses!.find((a) => a.node._key === t._key)
                                ? accesses!.filter((a) => a.node._key !== t._key)
                                : [
                                    ...(accesses ?? []),
                                    currentAccesses?.find((a) => a.node._key === t._key) ?? {
                                      node: t,
                                      link: {
                                        status: ToolAccessStatus.none,
                                        roles: [],
                                        notes: '',
                                        type: 'access_to',
                                        owner: user._key,
                                        _key: key,
                                        _id: 'link/' + key,
                                        _from: selectedUser._id,
                                        _to: t._id,
                                      },
                                    },
                                  ]
                            })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <a rel="noopener noreferrer" target="_blank" href={'/item/' + t._key}>
                          {t.title}
                        </a>
                      </TableCell>
                      <TableCell>
                        <SecretViewer value={t.password} />
                      </TableCell>
                      <TableCell style={checkUpdateStyles(t, 'roles')}>
                        <FormControl>
                          <InputLabel>Roles</InputLabel>
                          <Select
                            style={{minWidth: '200px'}}
                            multiple
                            disabled={!hasAccess(t)}
                            placeholder="Test"
                            onChange={(event) =>
                              setUpdatedAccesses((accesses) =>
                                accesses!.map((access: NodeConnection<ToolItem, ToolAccessLink>) => {
                                  if (access.link._key !== accessForTool(t)?._key) return access
                                  else return {...access, link: {...access.link, roles: event.target.value as string[]}}
                                }),
                              )
                            }
                            value={accessForTool(t)?.roles || []}
                          >
                            {t.roles.map((r) => (
                              <MenuItem value={r} key={r}>
                                {r}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell style={{...checkUpdateStyles(t, 'notes'), width: '100%'}}>
                        <TextField
                          disabled={!hasAccess(t)}
                          style={{width: '100%'}}
                          onChange={(event) =>
                            setUpdatedAccesses((accesses) =>
                              accesses!.map((access: NodeConnection<ToolItem, ToolAccessLink>) =>
                                access.link._key !== accessForTool(t)?._key
                                  ? access
                                  : {...access, link: {...access.link, notes: event.target.value}},
                              ),
                            )
                          }
                          value={accessForTool(t)?.notes ?? ''}
                        />
                      </TableCell>
                      <TableCell>{accessForTool(t)?.status ?? ToolAccessStatus.none}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <h3>Request Log</h3>
          <pre>{changesText || '- no changes -'}</pre>
          <hr />
          <div style={{margin: '1rem 0'}}>
            <Checkbox checked={hasDueDate} onChange={() => setHasDueDate((d) => !d)} />
            <TextField
              disabled={!hasDueDate}
              id="date"
              label="Due by ..."
              type="date"
              value={formatDate(dueDate)}
              onChange={(e) => setDueDate((e.target as HTMLInputElement).valueAsDate || new Date())}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <Button
            disabled={changesText.length < 1 || loading}
            color="primary"
            variant="contained"
            onClick={submitRequest}
          >
            Submit Request
          </Button>
          &nbsp;
          <Button disabled={changesText.length < 1 || loading} variant="contained" onClick={applyChanges}>
            Apply Immediately
          </Button>
          {loading && <CircularProgress />}
          <h3>Revisions</h3>
          <RevisionLog id={selectedUser._id} />
        </div>
      )}

      <FilteredAddDialog
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        onCreate={(data: any) => {
          setUsers((u) => [...(u ?? []), data])
          setSelectedUser(data)
        }}
      />
    </div>
  )
}

const FilteredAddDialog: React.FC<{
  onCreate: (data: any) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({open, setOpen, onCreate}) => {
  const handleCreate = (data: any) => {
    onCreate(data)
    setOpen(false)
  }

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth="md">
      <DialogContent>
        <InnerAddEntry
          hideFilter={true}
          selectedTemplate={templateForType('person')}
          setSelectedTemplate={() => {}}
          onCreate={handleCreate}
          autoFocus
        />
      </DialogContent>
    </Dialog>
  )
}

export const ApplyOnboardingPage = () => {
  const request: ChangeRequest = useMemo(() => JSON.parse(decodeURIComponent(window.location.search.substring(5))), [])
  const [expandedRequest, setExpandedRequest] = useState<ExpandedChangeRequest | null>()
  const [completed, setCompleted] = useState<boolean[]>([])
  const [applied, setApplied] = useState(false)

  useEffect(() => {
    async function fetch(): Promise<ExpandedChangeRequest> {
      return {
        ...request,
        add: (await api.getListByKeys<ToolItem>(request.add.map((a) => a._to))).map((tool, i) => ({
          link: request.add[i],
          node: tool,
        })),
        del: await api.getConnectionsByLinkKeys<ToolItem, ToolAccessLink>(request.del),
        change: (
          await api.getConnectionsByLinkKeys<ToolItem, ToolAccessLink>(request.change.map((c: any) => c.key))
        ).map((connection, i) => ({connection, field: request.change[i].field, value: request.change[i].value})),
      }
    }
    fetch().then((r) => {
      const list = []
      for (let i = 0; i < r.add.length + r.del.length + r.change.length; i++) list.push(false)
      setCompleted(list)
      setExpandedRequest(r)
    })
  }, [request])

  const confirm = () => {
    setApplied(true)
  }

  return (
    <div style={{padding: '3rem'}}>
      <Typography variant="h4">Change request for {request.for}</Typography>
      <Typography variant="subtitle1">Created by {request.by}</Typography>
      {expandedRequest && (
        <div>
          {expandedRequest.add.length > 0 && (
            <div>
              <hr />
              <Typography variant="overline">New Permissions</Typography>
              {expandedRequest.add.map((add, index) => {
                return (
                  <div key={add.link._id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completed[index]}
                          onChange={() => setCompleted(completed.map((c, i) => (i === index ? !c : c)))}
                          color="primary"
                        />
                      }
                      label={`Add to ${add.node.title} with roles "${add.link.roles.join(', ')}", note: "${
                        add.link.notes
                      }`}
                    />
                  </div>
                )
              })}
            </div>
          )}
          {expandedRequest.del.length > 0 && (
            <div>
              <hr />
              <Typography variant="overline">Removed Permissions</Typography>
              {expandedRequest.del.map((del, i) => {
                const index = i + expandedRequest.add.length
                return (
                  <div key={del.link._id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completed[index]}
                          onChange={() => setCompleted(completed.map((c, i) => (i === index ? !c : c)))}
                          color="primary"
                        />
                      }
                      label={`Remove from ${del.node.title} (previously ${del.link.roles.join(', ')})`}
                    />
                  </div>
                )
              })}
            </div>
          )}
          {expandedRequest.change.length > 0 && (
            <div>
              <hr />
              <Typography variant="overline">Changed Permissions</Typography>
              {expandedRequest.change.map((change, i) => {
                const index = i + expandedRequest.add.length + expandedRequest.del.length
                return (
                  <div key={change.connection.link._id + ':' + change.field}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completed[index]}
                          onChange={() => setCompleted(completed.map((c, i) => (i === index ? !c : c)))}
                          color="primary"
                        />
                      }
                      label={`On ${change.connection.node.title}, change ${change.field} to "${change.value}"`}
                    />
                  </div>
                )
              })}
            </div>
          )}
          <br />
          <br />
          <Button disabled={applied} variant="contained" onClick={confirm}>
            Confirm Changes
          </Button>
          {applied && 'All changes saved.'}
        </div>
      )}
    </div>
  )
}
