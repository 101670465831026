import {definition as faQuoteLeft} from '@fortawesome/free-solid-svg-icons/faQuoteLeft'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import {ContentItem} from '../ContentItem'
import {NodeItem, Template} from '../data'

const renderQuoteItem: Template<QuoteItem>['renderItem'] = (props) => (
  <ContentItem {...props}>
    <Typography variant="body2">“{props.node.content}”</Typography>
    <Typography variant="subtitle2">&mdash; {props.node.cite}</Typography>
  </ContentItem>
)

const renderQuotePage: Template<QuoteItem>['renderPage'] = ({node}) => (
  <Container>
    <TextField label="Content" fullWidth multiline value={(node as QuoteItem).content} />
    <TextField label="Cite" value={(node as QuoteItem).cite} />
  </Container>
)

const quoteTemplate: Template<QuoteItem> = {
  label: 'Quote',
  type: 'quote',
  title: 'Add Quote',
  icon: faQuoteLeft,
  renderItem: renderQuoteItem,
  renderPage: renderQuotePage,
  fields: [
    {label: 'Content', key: 'content', type: 'string', multiline: true},
    {label: 'Cite', key: 'cite', type: 'string'},
  ],
}
export default quoteTemplate

export interface QuoteItem extends NodeItem {
  type: 'quote'
  content: string
  cite: string
}
