import {faCalculator} from '@fortawesome/free-solid-svg-icons'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

export interface MethodItem extends NodeItem {
  description: string
  purpose: string
  method: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderMethodPage: Template<MethodItem>['renderPage'] = ({node}) => (
  <Container>
    <Typography variant="subtitle1" gutterBottom>
      {(node as MethodItem).description}
    </Typography>
    <div style={{height: '1rem'}}></div>
    <Typography variant="h5" gutterBottom>
      Purpose
    </Typography>
    <Typography variant="body1" gutterBottom>
      {(node as MethodItem).purpose}
    </Typography>
    <div style={{height: '1rem'}}></div>
    <Typography variant="h5" gutterBottom>
      Method
    </Typography>
    <Typography variant="body1">{(node as MethodItem).method}</Typography>
  </Container>
)

const methodTemplate: Template<MethodItem> = {
  label: 'Method',
  type: 'method',
  title: 'Add Method',
  icon: faCalculator,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Activity', key: 'activity', type: 'string', multiline: true},
    {label: 'Purpose', key: 'purpose', type: 'string', multiline: true},
    {label: 'Number of People', key: 'numberOfPeople', type: 'range'},
    {label: 'Duration', key: 'duration', type: 'range', min: 0, max: 120},
    {label: 'Description', key: 'describes', type: 'link', min: 0, max: 100},
  ],
}
export default methodTemplate

export interface MethodItem extends NodeItem {
  activity: string
  purpose: string
  numberOfPeople: [number, number]
  duration: [number, number]
}
