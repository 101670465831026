import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import EventIcon from '@material-ui/icons/Event'
import {User} from 'App'
import {NodeItem} from 'data'
import {AgendaItem} from 'data/agenda'
import React, {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {api} from './Api'
import {defaultValueForField, fieldOfTemplateType} from './data'

const AgendaHome: React.FC = () => {
  const history = useHistory()
  const [agendas, setAgendas] = React.useState<AgendaItem[]>([])
  const [title, setTitle] = React.useState<string>('')
  const navigateToItem = (itemKey: string) => history.push(`/item/${itemKey}`)

  useEffect(() => {
    api.safeDo(() => api.fetchAllOfType<AgendaItem>('agenda').then(setAgendas))
  }, [])

  const checkCreateKeyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.ctrlKey) createAgenda(title)
  }

  const [user] = useContext(User)

  const createAgenda = async (title: string) => {
    const agenda = {
      title: title,
      subtitle: '',
      agenda: defaultValueForField(fieldOfTemplateType('agenda', 'agenda')),
      intro: defaultValueForField(fieldOfTemplateType('agenda', 'intro')),
      type: 'agenda',
      owner: user._key,
    }
    const res: NodeItem = await api.create(agenda)

    navigateToItem(res._key)
  }

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <div style={{margin: '3rem'}}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5">
                  Add new agenda
                </Typography>
                <Grid container spacing={2} justify="space-between">
                  <Grid item xs>
                    <TextField
                      fullWidth
                      label="Title"
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value)
                      }}
                      onKeyUp={checkCreateKeyPressed}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        createAgenda(title)
                      }}
                    >
                      Create (Ctrl+Enter)
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item>
          <div style={{margin: '3rem'}}>
            <Grid container spacing={1}>
              <Grid item xs>
                <Typography gutterBottom variant="h6">
                  My agendas
                </Typography>
                <List>
                  {agendas
                    .filter((a) => a.owner === user._key)
                    .map((agenda) => (
                      <AgendaListItem
                        key={agenda._key}
                        agenda={agenda}
                        handleClick={() => navigateToItem(agenda._key)}
                      />
                    ))}
                </List>
              </Grid>
              <Grid item xs>
                <Typography gutterBottom variant="h6">
                  Other agendas
                </Typography>
                <List>
                  {agendas
                    .filter((a) => a.owner !== user._key)
                    .map((agenda) => (
                      <AgendaListItem
                        key={agenda._key}
                        agenda={agenda}
                        handleClick={() => navigateToItem(agenda._key)}
                      />
                    ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

interface AgendaListItemProps {
  agenda: AgendaItem
  handleClick: () => void
}

const AgendaListItem: React.FC<AgendaListItemProps> = ({agenda, handleClick}) => {
  const suffix = agenda.owner + (agenda.subtitle ? ' - ' + agenda.subtitle : agenda.subtitle)

  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary={agenda.title} secondary={suffix} />
      {agenda.private && <Chip label="Private" />}
    </ListItem>
  )
}

export default AgendaHome
