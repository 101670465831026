import './App.css'

import {
  faCalendar,
  faCaretRight,
  faListAlt,
  faProjectDiagram,
  faQuoteLeft,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AgendaHome from 'AgendaHome'
import {ApplyOnboardingPage, OnboardingPage} from 'OnboardingPage'
import QueryPage from 'QueryPage'
import React, {useEffect, useState} from 'react'
import {hot} from 'react-hot-loader/root'
import {BrowserRouter as Router, Redirect, Route, RouteProps} from 'react-router-dom'

import {api} from './Api'
import {ContentItem} from './ContentItem'
import {NodeItem} from './data'
import {PersonItem} from './data/person'
import {GraphPage} from './Graph'
import Home from './Home'
import {Login} from './Login'
import {NodePageRoute} from './NodePage'
import {Topbar} from './Topbar'
import {UserManagement} from './UserManagement'

export interface ViewContext {
  item: NodeItem
  mode: 'edit' | 'view'
  onSearchText: (text: string) => Promise<NodeItem[]>
  // onPaste: (data: DataTransfer) => Promise<NodeItem[]>
}

export const Notifications = React.createContext<(item: string) => void>((_) => {})
export const User = React.createContext<[PersonItem, (user: PersonItem) => void]>([
  {_id: '', _key: '', title: '', owner: '', type: 'person', phone: '', email: '', position: '', bookmarks: []},
  (_) => {},
])
export const ViewContextProvider = React.createContext<[ViewContext | null, (ctx: ViewContext) => void]>([
  null,
  (_) => {},
])

const PrivateRoute: React.FC<RouteProps & {viewContext: ViewContext | null; user: PersonItem}> = ({
  children,
  viewContext,
  user,
  ...props
}) => {
  const authenticatedView = () => (
    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
      <Topbar viewContext={viewContext} />
      <div>{children}</div>
    </div>
  )

  return <Route {...props} render={() => authenticatedView()} />
}

const App: React.FC = () => {
  const [notifications, setNotifications] = useState<string[]>([])
  const [viewContext, setViewContext] = useState<ViewContext | null>(null)
  const [user, setUser] = useState<PersonItem | null>(null)
  const [loadingUser, setLoadingUser] = useState(true)

  const showNotification = (text: string) => {
    setNotifications((n) => [...n, text])
  }

  useEffect(() => {
    api.safeDo(
      () =>
        api
          .self()
          .catch((err) => {
            if (err.response.status !== 401) throw err
            return null
          })
          .then((u) => setUser(u)),
      {post: () => setLoadingUser(false)},
    )
  }, [])

  if (loadingUser)
    return (
      <div style={{height: '400px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress />
      </div>
    )

  const routes = (user: PersonItem) => (
    <User.Provider value={[user, setUser]}>
      <Router>
        <PrivateRoute path="/" exact user={user} viewContext={viewContext}>
          <AgendaHome />
        </PrivateRoute>
        <PrivateRoute path="/home" exact user={user} viewContext={viewContext}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/me" exact user={user} viewContext={viewContext}>
          <Redirect to={'/item/' + user._key} />
        </PrivateRoute>
        <PrivateRoute path="/users" exact user={user} viewContext={viewContext}>
          <UserManagement />
        </PrivateRoute>
        <PrivateRoute path="/graph" exact user={user} viewContext={viewContext}>
          {' '}
          <GraphPage />
        </PrivateRoute>
        <PrivateRoute path="/item/:id" exact user={user} viewContext={viewContext}>
          <NodePageRoute />{' '}
        </PrivateRoute>
        <PrivateRoute path="/sample-search" exact user={user} viewContext={viewContext}>
          <SampleSearch />
        </PrivateRoute>
        <PrivateRoute path="/table" exact user={user} viewContext={viewContext}>
          <QueryPage />
        </PrivateRoute>
        <PrivateRoute path="/tools" exact user={user} viewContext={viewContext}>
          <OnboardingPage />
        </PrivateRoute>
        <PrivateRoute path="/apply-tools" exact user={user} viewContext={viewContext}>
          <ApplyOnboardingPage />
        </PrivateRoute>
      </Router>
    </User.Provider>
  )

  return (
    <Notifications.Provider value={showNotification}>
      <ViewContextProvider.Provider value={[viewContext, setViewContext]}>
        {user ? routes(user) : <Login setUser={setUser} />}
        {notifications.map((notification) => (
          <Snackbar
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            key={notification}
            autoHideDuration={1000}
            open={true}
            onClose={() => setNotifications((n) => n.filter((n) => notification !== n))}
            message={notification}
          />
        ))}
      </ViewContextProvider.Provider>
    </Notifications.Provider>
  )
}

const SampleSearch: React.FC = () => {
  return (
    <Container style={{padding: '6rem'}}>
      <TextField fullWidth label="Search ..." value="NE April quote lego" />
      <div style={{height: '1rem'}}></div>
      {[
        [faProjectDiagram, 'Neue Erfinder'],
        [faListAlt, 'Events'],
        [faCalendar, 'Family & Friends (April 2019)'],
        [faListAlt, 'Evaluation'],
        [faQuoteLeft, '“The part where we played with...'],
      ].map(([icon, label]) => (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: '1rem',
            padding: '0.2rem',
            margin: '0.2rem',
            border: '1px solid #ccc',
          }}
        >
          <FontAwesomeIcon icon={icon as IconDefinition} style={{margin: '0 0.5rem'}} />
          <Typography>{label}</Typography>
          <FontAwesomeIcon icon={faCaretRight} style={{margin: '0 0.5rem'}} />
        </div>
      ))}
      <div style={{height: '2rem'}}></div>
      <ContentItem node={{title: 'A', type: 'quote', owner: '', _key: 'a', _id: 'a'}}>
        <Typography variant="body2">“The part where we played with Lego was I think the...”</Typography>
        <Typography variant="subtitle2">&mdash; Laura, Teilnehmerin</Typography>
      </ContentItem>
    </Container>
  )
}

export default hot(App)
