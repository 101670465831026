import {faTasks} from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import TimeAgo from 'react-timeago'

import {ContentItem} from '../ContentItem'
import {NodeItem, Template} from '../data'
import {defaultRenderPage} from './template_fields'

export interface MissingInfoItem extends NodeItem {
  details: string
  requestFrom: string
}

const missingInfoTemplate: Template<MissingInfoItem> = {
  label: 'Missing Info',
  type: 'missing_info',
  title: 'Add Missing Info',
  icon: faTasks,
  renderItem: ({node, onDelete}) => (
    <ContentItem key={node._key} {...{onDelete}} node={node}>
      <Typography variant="subtitle2">{node.title}</Typography>
      <Typography variant="body2">{(node as MissingInfoItem).details}</Typography>
      <Typography variant="caption">
        Requested by {node.owner} <TimeAgo date={node.created_at} />
      </Typography>
    </ContentItem>
  ),
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Details', key: 'details', type: 'string'},
    {label: 'Request From', key: 'requestFrom', type: 'link'},
  ],
}

export default missingInfoTemplate
