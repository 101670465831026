import {Button, CircularProgress, Container, TextField} from '@material-ui/core'
import GridOnIcon from '@material-ui/icons/GridOn'
import TableChartIcon from '@material-ui/icons/TableChart'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import {api} from 'Api'
import {useBufferedSave} from 'BufferedSave'
import {NodeItem, renderItemForNode, templateForNode} from 'data'
import QueryTable from 'QueryTable'
import React, {useEffect, useMemo, useState} from 'react'

const QueryPage: React.FC = () => {
  const [query, setQuery] = useState('{"type": "slide_template"}')
  const [mode, setMode] = useState('table')
  const [results, setResults] = useState<NodeItem[]>([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setResults([])
  }, [query])

  useBufferedSave(query, (query) => {
    setError('')
    setResults([])
    api
      .safeDo(() => api.getList(JSON.parse(query)), {
        pre: () => setLoading(true),
        post: () => setLoading(false),
        onError: (err) => setError(err.toString()),
      })
      .then((res) => {
        setResults(res.filter((r) => !!templateForNode(r)))
      })
      .catch((err) => console.log(err))
  })

  const downloadResults = () => {
    var link = document.createElement('a')
    link.download = 'export.json'
    link.href = 'data:application/json,' + encodeURIComponent(JSON.stringify(results))
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const uploadFile = (files: FileList | null) => {
    if (!files) return
    const file = files[0]
    const reader = new FileReader()
    reader.onload = () => {
      const records = JSON.parse(reader.result as string).map((r: any) => ({...r, _key: undefined, _id: undefined}))
      api.safeDo(() => api._query(`FOR d IN @records INSERT d INTO documents`, {records}))
    }
    reader.readAsText(file)
  }

  return (
    <Container style={{padding: '6rem'}}>
      <Button variant="contained" onClick={() => downloadResults()}>
        Download JSON
      </Button>
      &nbsp; &nbsp;
      <input
        accept="application/json"
        style={{display: 'none'}}
        onChange={(e) => uploadFile(e.target.files)}
        multiple
        type="file"
        id="json-upload-input"
      />
      <label htmlFor="json-upload-input">
        <Button variant="contained" component="span">
          Upload JSON
        </Button>
      </label>
      &nbsp; &nbsp;
      <ToggleButtonGroup value={mode} exclusive onChange={(e, newAlginment) => setMode(newAlginment)}>
        <ToggleButton value="grid">
          <GridOnIcon />
        </ToggleButton>
        <ToggleButton value="table">
          <TableChartIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <TextField fullWidth label="Search ..." value={query} onChange={(e) => setQuery(e.target.value)} />
      <br />
      <br />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <span>{error || results.length + ' results'}</span>
          <br />
          <br />
          <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start'}}>
            {mode === 'table' ? (
              <ResultTable results={results} setResults={setResults} />
            ) : (
              <ResultGrid results={results} />
            )}
          </div>
        </>
      )}
    </Container>
  )
}

const ResultGrid: React.FC<{results: NodeItem[]}> = ({results}) => {
  return <>{results.map((node) => renderItemForNode({node, key: node._key}))}</>
}

const ResultTable: React.FC<{
  results: NodeItem[]
  setResults: React.Dispatch<React.SetStateAction<NodeItem[]>>
}> = ({results, setResults}) => {
  const allFields = useMemo(
    () => [
      '_key',
      ...Array.from(new Set(results.flatMap((x) => templateForNode(x).fields.map((f) => f.key)))),
      'public',
    ],
    [results],
  )
  return <QueryTable results={results} fields={allFields} setResults={setResults} />
}

export default QueryPage
