import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import React, {useEffect, useRef, useState} from 'react'
import {ForceGraph2D} from 'react-force-graph'

import {labelForLink, NodeConnection, NodeItem, NodeLink} from './data'
import {NodePageForId} from './NodePage'
import Search from './Search'

type GraphNode = {
  type: string
  name: string
  id: string
  x?: number
  y?: number
  color?: string
}
type GraphLink = {
  name: string
  type: string
  source: string | GraphNode
  target: string | GraphNode
}

export const Graph: React.FC<{startNode: NodeItem; onSelect: (item: GraphNode) => void}> = ({startNode, onSelect}) => {
  const [data, setData] = useState<{nodes: GraphNode[]; links: GraphLink[]}>({nodes: [], links: []})
  const graphEl = useRef<any>()

  useEffect(() => {
    const graph = graphEl.current
    if (graph) {
      graph.d3Force('link').distance(180)
      graph.d3Force('charge').distanceMax(500).strength(-1000)
    }
  }, [])

  const drawLink = (link: GraphLink, ctx: CanvasRenderingContext2D, globalScale: number) => {
    const source = link.source as GraphNode
    const target = link.target as GraphNode
    ctx.beginPath()
    ctx.moveTo(source.x!, source.y!)
    ctx.lineTo(target.x!, target.y!)
    ctx.stroke()

    const dx = target.x! - source.x!
    const dy = target.y! - source.y!
    const angle = Math.atan2(dy, dx)
    const scale = angle > Math.PI / 2 || angle < Math.PI / -2 ? -1 : 1

    ctx.save()
    ctx.textAlign = 'center'
    ctx.translate(source.x! + dx * 0.5, source.y! + dy * 0.5)
    ctx.rotate(angle)

    ctx.beginPath()
    ctx.fillStyle = '#000'
    ctx.moveTo(0, 0)
    ctx.lineTo(-8, -8)
    ctx.lineTo(-8, 8)
    ctx.closePath()
    ctx.fill()

    ctx.scale(scale, scale)
    ctx.fillStyle = '#000'
    ctx.fillText(link.name, 0, 12)
    ctx.restore()
  }

  const drawNode = (node: GraphNode, ctx: CanvasRenderingContext2D, globalScale: number) => {
    if (!node.x || !node.y || !node.color) return

    const label = node.name
    const fontSize = 12 // / globalScale
    ctx.font = `${fontSize}px sans-serif`
    const textWidth = ctx.measureText(label).width
    const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 0.9)

    ctx.fillStyle = node.color
    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, bckgDimensions[0], bckgDimensions[1])

    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#fff'
    ctx.fillText(label, node.x, node.y)
  }

  useEffect(() => {
    console.log(startNode._id)
    ;(async () => {
      // TODO: query
      /*const res = await api.queryConnections(
        `FOR vertex, edge IN 0..4 INBOUND @start GRAPH 'fored1'
        PRUNE false
        OPTIONS {uniqueVertices: 'path'}
        RETURN {'node': vertex, 'link': edge}`,
        {start: startNode._id},
      )*/
      const res: NodeConnection<NodeItem, NodeLink>[] = []
      setData({
        nodes: res.filter((c) => !!c.node).map((c) => ({id: c.node._id, name: c.node.title, type: c.node.type})),
        links: res
          .filter((c) => !!c.link)
          .map((c) => ({name: labelForLink(c.link), type: c.link.type, source: c.link._from, target: c.link._to})),
      })
    })()
  }, [startNode])

  return (
    <ForceGraph2D
      ref={graphEl}
      width={800}
      height={600}
      d3alphaDecay={0.02}
      d3VelocityDecay={0.1}
      nodeRelSize={20}
      onNodeClick={onSelect}
      nodeCanvasObject={drawNode}
      linkCanvasObject={drawLink}
      nodeAutoColorBy="type"
      linkAutoColorBy="type"
      graphData={data}
    />
  )
}

export const GraphPage: React.FC = () => {
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null)
  const [startNode, setStartNode] = useState<NodeItem | null>({
    _id: 'documents/querdenker',
    type: 'project',
    _key: 'querdenker',
    title: 'Querdenker',
    owner: '',
  })

  return (
    <>
      <div style={{height: '2rem'}}></div>
      <Container>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Search autoFocus onSelect={setStartNode} />
          </Grid>
          <Grid item>{startNode && <Graph onSelect={setSelectedNode} startNode={startNode} />}</Grid>
        </Grid>
      </Container>
      <hr style={{border: '1px solid #ccc'}} />
      {selectedNode && <NodePageForId id={selectedNode.id.split('/')[1]} />}
    </>
  )
}
