import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {AddEntry} from './AddEntry'
import {DumpedItemList, NodeItemList, TodoItemList} from './NodeItemList'
import {QuickWrite} from './QuickWrite'
import Search from './Search'
import {Tree} from './Tree'

const Home: React.FC = () => {
  const history = useHistory()
  const onCreated = (data: any) => history.push(`/item/${data._key}`)
  // const [user] = useContext(User)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const buildBookmarkList = () => (
    <Grid item>
      <div style={{margin: '3rem'}}>
        <NodeItemList
          // TODO: query
          // query={`FOR d IN DOCUMENT("documents", @userId).bookmarks ?: [] RETURN DOCUMENT("documents", d)`}
          filters={{}}
          content={(items) => (
            <>
              {items.map((item) => (
                <Grid key={item._key} item>
                  <Tree root={item} />
                </Grid>
              ))}
            </>
          )}
        />
      </div>
    </Grid>
  )

  return (
    <div>
      <Grid container>
        <Grid item>
          <div style={{margin: '3rem', minWidth: '400px'}}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Quick Write
                </Typography>
                <QuickWrite autoFocus />
              </CardContent>
            </Card>

            <div style={{height: '2rem'}}></div>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Search
                </Typography>
                <Search onSelect={(node) => history.push(`/item/${node._key}`)} />
              </CardContent>
            </Card>

            <div style={{height: '2rem'}}></div>

            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Add Entry
                </Typography>
                <AddEntry onCreate={onCreated} />
              </CardContent>
            </Card>
          </div>
        </Grid>

        <Grid item xs={3}>
          <div style={{margin: '3rem 0'}}>
            <Typography gutterBottom variant="h5">
              Updates
            </Typography>

            <Card>
              <CardContent>
                <Typography variant="body2">
                  Tom added two paragraphs to <strong>Knowledge Management Concept</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  3 hours ago
                </Typography>
              </CardContent>
            </Card>

            <div style={{height: '1rem'}}></div>

            <Card>
              <CardContent>
                <Typography variant="body2">
                  Marlen added a new method to <strong>Work Hacks</strong>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  5 hours ago
                </Typography>
              </CardContent>
            </Card>
          </div>
        </Grid>

        <Grid item>
          <div style={{margin: '3rem'}}>
            <TodoItemList />
            <div style={{height: '2rem'}}></div>
            <DumpedItemList />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
