import {faFileAlt} from '@fortawesome/free-solid-svg-icons'
import {NodeItem, Template} from 'data'
import GoogleDocs from 'GoogleDocs'

import {defaultRenderItem, renderPageWithEmbed} from './template_fields'

const googleDocTemplate: Template<GoogleDocItem> = {
  label: 'Google Doc',
  type: 'google_doc',
  title: 'Create Google Doc',
  icon: faFileAlt,
  renderItem: defaultRenderItem,
  renderPage: renderPageWithEmbed,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'URL', key: 'url', type: 'string', hidden: true},
  ],
  parseUrl: (url) => (url.match('^https://docs.google.com/document/') ? Promise.resolve({url}) : null),
  beforeCreate: async (item) => {
    if (!item.url) item.url = await GoogleDocs.createDoc(item.title)
  },
}
export default googleDocTemplate

export interface GoogleDocItem extends NodeItem {
  url: string
}
