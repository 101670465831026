import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {AddEntry} from './AddEntry'
import {NodeItem} from './data'
import {BookmarkList} from './NodeItemList'
import Search from './Search'

const Navigation: React.FC<{open: boolean; onClose: () => void}> = ({open, onClose}) => {
  const history = useHistory()

  const openItem = (item: NodeItem) => {
    onClose()
    history.push(`/item/${item._key}`)
  }

  return (
    <Popover
      anchorReference="anchorPosition"
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      anchorPosition={{left: 0, top: 0}}
      elevation={24}
      onClose={() => onClose()}
      open={open}
    >
      <Grid container style={{width: '1200px', padding: '1.5rem'}} spacing={2}>
        <Grid item>
          <BookmarkList />
        </Grid>
        <Grid item xs={4}>
          <Search autoFocus onSelect={openItem} />
        </Grid>
        <Grid item>
          <AddEntry maxItems={8} onCreate={openItem} />
        </Grid>
      </Grid>
    </Popover>
  )
}
export default Navigation
