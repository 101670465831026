import {definition as faBookmark} from '@fortawesome/free-solid-svg-icons/faBookmark'
import {definition as faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Fab from '@material-ui/core/Fab'
import Grid from '@material-ui/core/Grid'
import {SlidesItem} from 'data/google_slides'
import {SlideTemplateItem} from 'data/slide_template'
import React, {useContext, useEffect, useRef, useState} from 'react'

import {api} from './Api'
import {ViewContextProvider} from './App'
import {ContentItemWithPicture} from './ContentItem'
import {NodeItem} from './data'
import FilterList from './FilterList'
import GoogleDocs from './GoogleDocs'
import {useShortcut} from './shortcut'

export const SlideTemplatePreview: React.FC<{node: SlideTemplateItem; activePresentationId: string}> = ({
  node,
  activePresentationId,
}) => {
  const handleClick = () => {
    /*const transfer = new DataTransfer()
        transfer.items.add(googleSlideTemplateType, (node as ItemWithText).content)
        transfer.items.add('text/plain', ' ')

        console.log('copying', (node as ItemWithText).content)
        ;(navigator.clipboard as any).write(transfer)
            .then(() => showNotification('Copied!'))
            .catch(api.handleError)*/
    /*api.safeDo(() => GoogleDocs.copySlide(
            {presentationId: node.presentationId, pageObjectId: node.slideId},
            activePresentationId))*/
  }
  const [url, setUrl] = useState(
    'https://images.unsplash.com/photo-1573491602162-17ca6f0d3806?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=72',
  )

  useEffect(() => {
    GoogleDocs.getThumbnailForSlide(node.presentationId, node.slideIds[0]).then((url) => setUrl(url))
  }, [node.presentationId, node.slideIds])

  // picture={'https://images.unsplash.com/photo-1573491602162-17ca6f0d3806?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=200&q=72'}>
  return (
    <ContentItemWithPicture onClick={handleClick} node={node} picture={url}>
      {node.title}
    </ContentItemWithPicture>
  )
}

export const idForGoogleSlides = (url: string) => {
  return url.match(/\/d\/([^/]+)/)![1]
}

export const PresentationPage: React.FC<{node: SlidesItem}> = ({node}) => {
  const url = node.url
  const [slideTemplates, setSlideTemplates] = useState<NodeItem[]>([])
  const embedPage = useRef<HTMLIFrameElement>(null)

  const [viewContextDialogOpen, setViewContextDialogOpen] = useState(false)

  const [, setViewContext] = useContext(ViewContextProvider)

  useShortcut('B', () => {
    setViewContextDialogOpen(true)
  })

  useEffect(() => {
    setViewContext({
      item: node,
      mode: 'edit',
      onSearchText: (text: string) => {
        return Promise.resolve([])
      },
    })
    api.getListByType<SlideTemplateItem>('slide_template').then(setSlideTemplates)
  }, [node, setViewContext])

  const createTemplate = () => {
    GoogleDocs.copySlide(
      {presentationId: '1JJGXbVoQ5WYpjmLLcnTGNhFCdvwMtir07Nl9UTLCt2A', pageObjectId: ''},
      idForGoogleSlides(url),
    ).catch(console.log)
    // console.log(embedPage.current!.contentWindow!.location.href)
    // const matches = embedPage.current!.contentWindow!.location.href.match(/\/d\/([^\/]+)\/edit#slide=id\.([A-Za-z0-9_]+)/)
    // console.log(matches)
  }

  return (
    <>
      <ViewContextActionsDialog open={viewContextDialogOpen} onClose={() => setViewContextDialogOpen(false)} />

      <Grid container>
        {slideTemplates.map((template) => (
          <Grid key={template._id} item>
            <SlideTemplatePreview activePresentationId={idForGoogleSlides(url)} node={template as SlideTemplateItem} />
          </Grid>
        ))}
      </Grid>

      <Fab onClick={createTemplate}>
        <FontAwesomeIcon icon={faBookmark} />
      </Fab>

      <iframe ref={embedPage} title={node.title} className="page-embed" src={url}></iframe>
    </>
  )
}

const ViewContextActionsDialog: React.FC<{open: boolean; onClose: () => void}> = ({open, onClose}) => {
  // const [searchText, setSearchText] = useState('')
  const items = [{label: 'Add as a slide'}]

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <FilterList
          autoFocus
          onSelect={(e) => {}}
          label="Search .."
          items={items}
          iconCb={(e) => faQuestion}
          keyCb={(e) => e.label}
          titleCb={(e) => e.label}
        />
      </DialogContent>
    </Dialog>
  )
}
