import {useCallback, useEffect} from 'react'

// can't be in react state because events trigger more frequently
const hadKeys: {[key: string]: boolean} = {}

export const useShortcut = (key: string, callback: () => void) => {
  const listener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key && event.ctrlKey) {
        if (!hadKeys[key]) {
          hadKeys[key] = true
          callback()
        }
        event.preventDefault()
        event.stopPropagation()
      }
    },
    [callback, key],
  )

  const upListener = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        delete hadKeys[key]
        event.preventDefault()
        event.stopPropagation()
      }
    },
    [key],
  )

  useEffect(() => {
    window.addEventListener('keydown', listener, true)
    window.addEventListener('keyup', upListener, true)
    return () => {
      window.removeEventListener('keydown', listener, true)
      window.removeEventListener('keyup', upListener, true)
    }
  }, [listener, upListener])
}
