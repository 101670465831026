import {definition as faEye} from '@fortawesome/free-solid-svg-icons/faEye'
import {definition as faPlusCircle} from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import {definition as faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion'
import {definition as faSave} from '@fortawesome/free-solid-svg-icons/faSave'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Slider from '@material-ui/core/Slider'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {api} from 'Api'
import clsx from 'clsx'
import ConnectButton from 'ConnectButton'
import {ContentItem} from 'ContentItem'
import {
  defaultValueForField,
  iconForLinkType,
  iconForNode,
  labelForLink,
  NodeItem,
  passiveLabelForLinkType,
  renderPageForNode,
  RenderPageProps,
  Template,
  TemplateField,
  templateForNode,
} from 'data'
import {Editor, EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import React, {useState} from 'react'
import {ellipsize} from 'utils'

import {AgendaBuilder} from './agenda_builder'

export const renderPageWithEmbed: Template<NodeItem & {url: string}>['renderPage'] = ({node}) => (
  <>
    <Button onClick={() => window.open(node.url)}>Open External</Button>
    <iframe title={node.title} className="page-embed" src={node.url}></iframe>
  </>
)

export const renderContentItem: Template<NodeItem & {content: string}>['renderItem'] = (props) => (
  <ContentItem {...props}>
    {props.node.title ? (
      <Typography variant="subtitle2">{props.node.title}</Typography>
    ) : (
      props.link && <Typography variant="subtitle2">{labelForLink(props.link)}</Typography>
    )}
    <Typography variant="body2">{ellipsize(props.node.content)}</Typography>
  </ContentItem>
)

export const defaultRenderItem: Template<NodeItem>['renderItem'] = (props) => (
  <ContentItem {...props}>
    <Typography variant="subtitle2" gutterBottom>
      <FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={iconForNode(props.node) || faQuestion} />
      {templateForNode(props.node).label}
    </Typography>
    <Typography>{props.node.title}</Typography>
  </ContentItem>
)

export const defaultRenderPage: Template<NodeItem>['renderPage'] = (props) =>
  props.fullWidth ? (
    <div style={{paddingLeft: '24px', paddingRight: '24px'}}>
      <ScreenFormForNode {...props} />
    </div>
  ) : (
    <Container>
      <ScreenFormForNode {...props} />
    </Container>
  )
export const emptyRenderPage: Template<NodeItem>['renderPage'] = (_) => <></>

export const ScreenFormForNode: React.FC<RenderPageProps<NodeItem>> = ({
  node,
  setNode,
  isEditing,
  notifyChange,
  relatedNodes,
}) => {
  const template = templateForNode(node)

  return (
    <>
      {template.fields
        .filter((field) => field.key !== 'title')
        .map((field) => {
          const value =
            field.type === 'link' ? relatedNodes.find((c) => c.link.type === field.key)?.node : node[field.key]
          return isEditing ? (
            largeEditorForType({
              field: field,
              value,
              onChange: (value) => {
                if (field.type === 'link') {
                  // is saved immediately
                } else {
                  notifyChange(field)
                  setNode((n) => ({
                    ...n,
                    // support both setState(old => new) and setState(new) invocations
                    [field.key]: typeof value === 'function' ? value(n[field.key]) : value,
                  }))
                }
              },
            })
          ) : (
            <div style={{marginBottom: '1rem'}} key={field.key}>
              {viewForType({field, value})}
            </div>
          )
        })}
    </>
  )
}

const wrapFunc = (field: TemplateField, key?: any) => (el: JSX.Element) => (
  <div key={key ?? field.key} className="field">
    <div className="field-caption">{field.label}</div>
    {el}
  </div>
)

type BasicViewProps = {field: TemplateField; value: any; key?: any; onChange?: (val: any) => void}

const wantsVerticalArrayEditor = (field: TemplateField) => ['agenda', 'richtext'].includes(field.type)
const buildArrayFor = <T extends BasicViewProps>(props: T & {viewFunc: (props: T) => JSX.Element}) => {
  if (props.field.array) {
    return wrapFunc(props.field)(
      <div
        className={clsx(
          'fields-array',
          wantsVerticalArrayEditor(props.field) ? 'fields-array-vertical' : 'fields-array-horizontal',
        )}
      >
        {(props.value ?? []).map((val: any, index: number) =>
          props.onChange
            ? props.viewFunc({
                ...props,
                value: val,
                key: index,
                onChange: (newVal) =>
                  props.onChange!(props.value.map((oldVal: any, i: number) => (i === index ? newVal : oldVal))),
              })
            : props.viewFunc({...props, value: val, key: index}),
        )}
        {props.onChange && (
          <IconButton
            onClick={() => props.onChange!([...(props.value ?? []), defaultValueForField(props.field, true)])}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </IconButton>
        )}
      </div>,
    )
  }
  return wrapFunc(props.field)(props.viewFunc(props))
}

export const viewForType = (props: BasicViewProps): JSX.Element =>
  buildArrayFor({...props, viewFunc: viewForTypeSingle})

const viewForTypeSingle = ({field, value, key}: BasicViewProps): JSX.Element => {
  switch (field.type) {
    case 'boolean':
      return <Checkbox key={key} checked={value ?? false} />
    case 'richtext':
      return (
        <div
          key={key}
          dangerouslySetInnerHTML={{__html: stateToHTML((value as EditorState).getCurrentContent())}}
        ></div>
      )
    case 'string':
      return (
        <div style={{padding: '4px'}} key={key}>
          {value}
        </div>
      )
    case 'range':
      return <div key={key}>{value[0] + ' - ' + value[1]}</div>
    case 'agenda':
      return <AgendaBuilder key={key} setAgenda={(_) => {}} isEditing={false} agenda={value} />
    case 'link':
      return (value = value ? (
        <div key={key}>
          {renderPageForNode({
            relatedNodes: [] /* FIXME: incorrect, not sure here */,
            node: value,
            isEditing: false,
            notifyChange: () => {},
            setNode: (n) => {},
          })}
        </div>
      ) : (
        <div key={key}>- Empty Link -</div>
      ))
    case 'color':
      return (
        <div key={key} className="color-view" style={{backgroundColor: value}}>
          {value}
        </div>
      )
    case 'secret':
      return <SecretViewer value={value} key={key} />
    default:
      return <div key={key}>Unsupported Type `{field.type}`</div>
  }
}

export const SecretViewer: React.FC<{value: string}> = ({value}) => {
  const reveal = async () => {
    const secret = prompt('Enter secret key')
    if (secret) alert(await api.safeDo(() => api.decrypt(secret, value)))
  }

  return (
    <div>
      {!value ? (
        ' - '
      ) : (
        <div>
          ••••&nbsp;
          <IconButton onClick={reveal} size="small">
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export const largeEditorForType = (props: BasicViewProps): JSX.Element =>
  buildArrayFor({...props, viewFunc: largeEditorForTypeSingle})

const largeEditorForTypeSingle = ({field, value, onChange, key}: BasicViewProps): JSX.Element => {
  if (!onChange) return <></>
  switch (field.type) {
    case 'boolean':
      return <Checkbox key={key} checked={value ?? false} onChange={onChange} />
    case 'richtext':
      return <Editor key={key} editorState={value} onChange={(e) => onChange(e)} />
    case 'string':
      return (
        <TextField key={key} value={value} onChange={(e) => onChange(e.target.value)} multiline={field.multiline} />
      )
    case 'agenda':
      return <AgendaBuilder key={key} setAgenda={onChange} isEditing={true} agenda={value} />
    case 'color':
      return <input key={key} onChange={(e) => onChange(e.target.value)} type="color" value={value} />
    case 'range':
      return (
        <div key={key} style={{maxWidth: '300px'}}>
          <Slider valueLabelDisplay="auto" onChange={(_, newValue) => onChange(newValue)} value={value} />
        </div>
      )
    case 'link':
      return (
        <div key={key}>
          {value ? (
            renderPageForNode({
              relatedNodes: [] /* FIXME: incorrect, not sure here */,
              node: value,
              isEditing: false,
              notifyChange: () => {},
              setNode: (n) => {},
            })
          ) : (
            <>
              <ConnectButton
                onCreate={(connection) => onChange(connection.node)}
                currentLink={value}
                isTarget={field.linkReversed}
                button={(onClick) => (
                  <Button className="no-uppercase" onClick={(e) => onClick(e.currentTarget, field.key)}>
                    <FontAwesomeIcon className="margin-right" icon={iconForLinkType(field.key)} />
                    {passiveLabelForLinkType(field.key, value ? value.title : '...')}
                  </Button>
                )}
              />
              - Empty Link -
            </>
          )}
        </div>
      )
    case 'secret':
      return <SecretEditor key={key} onChange={onChange} />
    default:
      return <div key={key}>Unsupported Type `{field.type}`</div>
  }
}

const SecretEditor: React.FC<{onChange: (val: any) => void}> = ({onChange}) => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const encrypt = async () => {
    const secret = prompt('Enter secret key')
    if (secret)
      await api
        .safeDo(() => api.encrypt(secret, value), {pre: () => setLoading(true), post: () => setLoading(false)})
        .then((value) => onChange(value))
  }

  return (
    <>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="password"
        onKeyPress={(e) => e.key === 'Enter' && encrypt()}
      />
      <IconButton onClick={encrypt} disabled={loading} size="small">
        <FontAwesomeIcon icon={faSave} />
      </IconButton>
      {loading && 'Encrypting ...'}
    </>
  )
}

export const smallEditorForType: (
  props: BasicViewProps & {
    checkCreateKeyPressed: (evt: React.KeyboardEvent) => void
    inputRef?: React.MutableRefObject<HTMLInputElement | undefined>
    loading: boolean
  },
) => JSX.Element = (props) => buildArrayFor({...props, viewFunc: smallEditorForTypeSingle})

const smallEditorForTypeSingle = ({
  field,
  checkCreateKeyPressed,
  inputRef,
  loading,
  value,
  onChange,
  key,
}: BasicViewProps & {
  checkCreateKeyPressed: (evt: React.KeyboardEvent) => void
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>
  loading: boolean
}): JSX.Element => {
  if (!onChange) return <></>
  switch (field.type) {
    case 'agenda':
      // an agenda can only be edited in the large form
      return <div key={key}></div>
    case 'boolean':
      return <Checkbox key={key} checked={value ?? false} onChange={(e) => onChange(e.target.checked)} />
    case 'file':
      return (
        <TextField
          key={key}
          label={field.label}
          inputRef={inputRef}
          onKeyUp={checkCreateKeyPressed}
          fullWidth
          disabled={loading}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )
    case 'string':
      return (
        <TextField
          key={key}
          label={field.label}
          inputRef={inputRef}
          onKeyUp={checkCreateKeyPressed}
          fullWidth
          multiline={field.multiline}
          disabled={loading}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      )
    case 'color':
      return <input key={key} onChange={(e) => onChange(e.target.value)} type="color" value={value} />
    case 'range':
      return (
        <div key={key}>
          <Typography id="range-slider" gutterBottom>
            {field.label} ({value[0]} - {value[1]})
          </Typography>
          <Slider valueLabelDisplay="auto" onChange={(_, newValue) => onChange(newValue)} value={value} />
        </div>
      )
    case 'link':
      return (
        <ConnectButton
          key={key}
          selectedLinkType={field.key}
          onSelect={(v) => onChange(v)}
          isTarget={field.linkReversed}
          button={(onClick) => (
            <Button className="no-uppercase" onClick={(e) => onClick(e.currentTarget, field.key)}>
              <FontAwesomeIcon className="margin-right" icon={iconForLinkType(field.key)} />
              {passiveLabelForLinkType(field.key, value ? value.title : '...')}
            </Button>
          )}
        />
      )
    case 'richtext':
      return <Editor key={key} editorState={value} onChange={(e) => onChange(e)} />
    case 'secret':
      return <SecretEditor key={key} onChange={onChange} />
    default:
      return <div key={key}>Unknown type {field.type}</div>
  }
}
