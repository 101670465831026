import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import React, {useCallback} from 'react'
import {DragDropContext, Draggable, Droppable, DroppableProvided} from 'react-beautiful-dnd'

export interface Row {
  columns: string[]
  color: number
  duration: number
}

export interface Agenda {
  colors: {label: string; color: string}[]
  columnLabels: string[]
  rows: Row[]
  start: number
}

const formatMinutes = (minutes: number) => {
  const hours = minutes / 60
  const mins = minutes % 60
  return (hours < 10 ? '0' : '') + Math.floor(hours) + ':' + (mins < 10 ? '0' : '') + mins
}

const AgendaRow: React.FC<{
  rowIndex: number
  isEditing: boolean
  updateStart: (minutes: number) => void
  updateDuration: (row: number, minutes: number) => void
  updateRowColor: (a: number, b: number) => void
  updateCell: (row: number, column: number, text: string) => void
  colors: {label: string; color: string}[]
  columnLabels: string[]
  row: Row
  start: number
  end: number
  id: string
}> = React.memo(
  ({
    rowIndex,
    isEditing,
    updateStart,
    updateDuration,
    updateRowColor,
    updateCell,
    colors,
    columnLabels,
    row,
    start,
    end,
    id,
  }) => {
    return (
      <Draggable draggableId={id} index={rowIndex} isDragDisabled={!isEditing}>
        {(provided, _) => (
          <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <TableCell>
              {rowIndex === 0 && isEditing ? (
                <input type="time" value={formatMinutes(start)} onChange={(e) => updateStart(e.target.valueAsNumber)} />
              ) : (
                formatMinutes(start)
              )}
            </TableCell>
            <TableCell>{formatMinutes(end!)}</TableCell>
            <TableCell>
              {isEditing ? (
                <input
                  type="number"
                  style={{width: '50px'}}
                  value={row.duration || 0}
                  onChange={(e) => updateDuration(rowIndex, e.target.valueAsNumber)}
                />
              ) : (
                row.duration
              )}
            </TableCell>
            {columnLabels.map((_, columnIndex) => (
              <AgendaCell
                key={columnIndex}
                isEditing={isEditing}
                text={row.columns[columnIndex] ?? ''}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                color={colors[row.color]?.color}
                updateCell={updateCell}
              />
            ))}
            {isEditing && <TableCell /> /* the "add-column" cells */}
            <TableCell>
              {isEditing ? (
                <Select value={row.color ?? -1} onChange={(e) => updateRowColor(rowIndex, e.target.value as number)}>
                  <MenuItem value={-1}>- None -</MenuItem>
                  {colors.map((c, i) => (
                    <MenuItem key={i} value={i}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                colors[row.color]?.label ?? ''
              )}
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    )
  },
)

const AgendaCell: React.FC<{
  isEditing: boolean
  text: string
  rowIndex: number
  columnIndex: number
  color?: string
  updateCell: (row: number, column: number, text: string) => void
}> = React.memo(({isEditing, text, color, columnIndex, updateCell, rowIndex}) => {
  const stylesFor = () => {
    const background = columnIndex === 0 ? color : undefined
    if (background) {
      // FIXME can certainly be improved
      const colorSum =
        parseInt(background.substring(1, 3), 16) +
        parseInt(background.substring(3, 5), 16) +
        parseInt(background.substring(5, 7), 16)
      const foreground = background && colorSum / (3 * 255) < 0.5 ? '#ffffff' : '#000000'
      return {backgroundColor: background, color: foreground}
    }
    return {}
  }

  return (
    <TableCell style={stylesFor()}>
      {isEditing ? (
        <TextField
          style={{background: '#fff'}}
          multiline
          value={text}
          onChange={(e) => updateCell(rowIndex, columnIndex, e.target.value)}
        />
      ) : (
        text
      )}
    </TableCell>
  )
})

export const AgendaBuilder: React.FC<{
  isEditing: boolean
  agenda: Agenda
  setAgenda: React.Dispatch<React.SetStateAction<Agenda>>
}> = ({agenda, setAgenda, isEditing}) => {
  const _updateIndex = (array: string[], index: number, value: string) => {
    const result = Array.from(array)
    result[index] = value
    return result
  }

  const updateCell = useCallback(
    (rowIndex: number, columnIndex: number, value: string) => {
      setAgenda((a) => ({
        ...a,
        rows: a.rows.map((r, i) => (i !== rowIndex ? r : {...r, columns: _updateIndex(r.columns, columnIndex, value)})),
      }))
    },
    [setAgenda],
  )

  const updateDuration = useCallback(
    (rowIndex: number, duration: number) => {
      setAgenda((a) => ({...a, rows: a.rows.map((r, i) => (i !== rowIndex ? r : {...r, duration: duration}))}))
    },
    [setAgenda],
  )

  const reorderRow = (from: number, to: number) => {
    setAgenda((a) => {
      const result = Array.from(a.rows)
      const [removed] = result.splice(from, 1)
      result.splice(to, 0, removed)

      return {...a, rows: result}
    })
  }

  const updateStart = useCallback(
    (start: number | undefined) => {
      if (!start) return
      setAgenda((a) => ({...a, start: start / (1000 * 60)}))
    },
    [setAgenda],
  )

  const addRow = () => {
    setAgenda((a) => ({...a, rows: [...a.rows, {columns: [], duration: 30, color: -1}]}))
  }

  let time = agenda.start
  const rowsMeta = agenda.rows.map((row, i) => {
    const duration = row.duration || 0
    time += duration
    return {
      start: time - duration,
      end: time,
      id: i.toString(),
    }
  })

  const updateRowColor = useCallback(
    (rowIndex: number, colorIndex: number) => {
      setAgenda((a) => ({...a, rows: a.rows.map((r, i) => (i !== rowIndex ? r : {...r, color: colorIndex}))}))
    },
    [setAgenda],
  )

  const updateColor = (colorIndex: number, property: 'color' | 'label', value: string) => {
    setAgenda((a) => ({
      ...a,
      colors: a.colors.map((c, i) => {
        if (i !== colorIndex) return c
        let copy = {...c}
        copy[property] = value
        return copy
      }),
    }))
  }

  const addColor = () => {
    setAgenda((a) => ({...a, colors: [...a.colors, {color: '#ff0000', label: ''}]}))
  }

  const updateColumnLabel = (label: string, index: number) =>
    setAgenda((a) => ({...a, columnLabels: a.columnLabels.map((l, i) => (i === index ? label : l))}))

  const appendColumn = () => setAgenda((a) => ({...a, columnLabels: [...a.columnLabels, 'unnamed']}))

  return (
    <div>
      <DragDropContext
        onDragEnd={(r) => {
          if (r.destination && r.source.index !== r.destination.index) reorderRow(r.source.index, r.destination.index)
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Duration</TableCell>
              {agenda.columnLabels.map((c, i) => (
                <TableCell key={i}>
                  {isEditing ? <input value={c} onChange={(e) => updateColumnLabel(e.target.value, i)} /> : c}
                </TableCell>
              ))}
              {isEditing && (
                <TableCell>
                  <IconButton size="small" onClick={appendColumn}>
                    <FontAwesomeIcon icon={faPlus} />
                  </IconButton>
                </TableCell>
              )}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="table">
            {(droppableProvided: DroppableProvided) => (
              <TableBody
                ref={(ref: HTMLElement | null) => {
                  droppableProvided.innerRef(ref)
                }}
                {...droppableProvided.droppableProps}
              >
                {agenda.rows.map((row, rowIndex) => (
                  <AgendaRow
                    key={rowsMeta[rowIndex].id}
                    id={rowsMeta[rowIndex].id}
                    start={rowsMeta[rowIndex].start}
                    end={rowsMeta[rowIndex].end}
                    colors={agenda.colors}
                    rowIndex={rowIndex}
                    row={row}
                    columnLabels={agenda.columnLabels}
                    updateCell={updateCell}
                    updateDuration={updateDuration}
                    updateRowColor={updateRowColor}
                    updateStart={updateStart}
                    isEditing={isEditing}
                  />
                ))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      {isEditing && (
        <div className="field">
          <Button onClick={addRow} variant="outlined" size="small" startIcon={<FontAwesomeIcon icon={faPlus} />}>
            Add Row
          </Button>
        </div>
      )}
      <div className="field">
        {agenda.colors.map((def, i) => (
          <div key={i}>
            <input
              type="color"
              disabled={!isEditing}
              value={def.color}
              onChange={(e) => updateColor(i, 'color', e.target.value)}
            />
            {isEditing ? (
              <input type="text" value={def.label ?? ''} onChange={(e) => updateColor(i, 'label', e.target.value)} />
            ) : (
              def.label
            )}
          </div>
        ))}
        {isEditing && (
          <IconButton size="small" onClick={addColor}>
            <FontAwesomeIcon icon={faPlus} />
          </IconButton>
        )}
      </div>
    </div>
  )
}
