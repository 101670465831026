import {faBuilding} from '@fortawesome/free-solid-svg-icons'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const customerTemplate: Template<CustomerItem> = {
  label: 'Customer',
  type: 'customer',
  title: 'Add Customer',
  icon: faBuilding,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Brand Color', key: 'brandColor', type: 'color'},
    {label: 'Palette', key: 'palette', type: 'color', array: true},
  ],
}
export default customerTemplate

export interface CustomerItem extends NodeItem {
  brandColor: string
}
