import {faPager} from '@fortawesome/free-solid-svg-icons'
import React from 'react'

import {ContentItemWithPicture} from '../ContentItem'
import {NodeItem, RenderItemProps, Template} from '../data'
import {defaultRenderPage} from './template_fields'

const renderSlideTemplate = ({node}: RenderItemProps<SlideTemplateItem>) => {
  return (
    <ContentItemWithPicture node={node} picture={'https://know.4ed1.com/slides/template/' + node._key}>
      <strong>{node.title}</strong>
      <br />
      {(node as any).templateType}
    </ContentItemWithPicture>
  )
}

const slideTemplateTemplate: Template<SlideTemplateItem> = {
  label: 'Slide Template',
  type: 'slide_template',
  title: 'Add Slide Template',
  icon: faPager,
  renderItem: renderSlideTemplate,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Slide IDs', key: 'slideIds', type: 'string[]'},
    {label: 'Template Type', key: 'templateType', type: 'string'},
    {label: 'Presentation ID', key: 'presentationId', type: 'string'},
    {label: 'Presentation Title', key: 'presentationTitle', type: 'string'},
    {label: 'Subsection', key: 'subsection', type: 'string'},
  ],
}
export default slideTemplateTemplate

export interface SlideTemplateItem extends NodeItem {
  type: 'slide_template'
  slideIds: string[]
  templateType: string
  presentationId: string
  presentationTitle: string
  subsection: string
}
