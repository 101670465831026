import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import React, {useEffect, useState} from 'react'

import {api} from './Api'
import {PersonItem} from './data/person'

export const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<PersonItem[] | undefined>(undefined)

  /* const addUser = async () => {
    let email: string | null = ''
    let password: string | null = ''
    if (!(email = prompt('Email?'))) return
    if (!(password = prompt('Password?'))) return

    const user = await api.safeDo(() => api.signUp(email!, password!))
    setUsers(l => (l ? [...l, user!] : l))
  }

  const changeName = async (user: PersonItem) =>
    ifVal(prompt('New Name?'), title =>
      api.safeDo(() =>
        api
          .updateNode(user._id, {title})
          .then(_ => setUsers(l => l!.map(u => (u._id === user._id ? {...u, title} : u)))),
      ),
    ) */

  useEffect(() => {
    api.getListByType<PersonItem>('person').then(setUsers)
  }, [])

  return (
    <Container>
      {!users ? (
        <CircularProgress />
      ) : (
        <List>
          {users.map((u) => (
            <ListItem button>
              <ListItemText>{u.title}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}
      {/*<Fab onClick={addUser} color="primary">
        <FontAwesomeIcon icon={faPlus} />
          </Fab>*/}
    </Container>
  )
}
