/* Copied from https://material-ui.com/components/tables/#sorting-amp-selecting */
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import {createStyles, lighten, makeStyles, Theme} from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import FilterListIcon from '@material-ui/icons/FilterList'
import clsx from 'clsx'
import {NodeItem} from 'data'
import React from 'react'

import {api} from './Api'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: {[key in Key]: number | string}, b: {[key in Key]: number | string}) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {classes, onSelectAllClick, order, orderBy, numSelected, headCells, rowCount, onRequestSort} = props
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{'aria-label': 'select all desserts'}}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
)

interface EnhancedTableToolbarProps {
  selectedKeys: string[]
  fields: string[]
  setResults: React.Dispatch<React.SetStateAction<NodeItem[]>>
  clearSelection: () => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const classes = useToolbarStyles()
  const {selectedKeys, fields, setResults} = props
  const numSelected = selectedKeys.length
  const clearSelection = props.clearSelection

  const updateTable = (edits: BatchEdit) => {
    setResults((results) => results.map((row) => (selectedKeys.includes(row._key) ? {...row, ...edits} : row)))
  }

  const handleClose = (edits: BatchEdit) => {
    setOpen(false)
    api
      .safeDo(
        () =>
          api._query('FOR key IN @keys UPDATE key WITH @update IN documents', {
            keys: selectedKeys, // list of db keys
            update: {...edits},
          }),
        {
          pre: () => setLoading(true),
          post: () => setLoading(false),
        },
      )
      .then(() => updateTable(edits))
  }

  const deleteSelection = () => {
    if (!window.confirm('This action cannot be undone. Continue?')) return
    api
      .safeDo(() => api.deleteNodesPermanently(selectedKeys))
      .then(() => {
        setResults((results) => results.filter((r) => !selectedKeys.includes(r._key)))
        clearSelection()
      })
  }

  const batchEditSelection = () => {
    setOpen(true)
  }

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Query results
          </Typography>
        )}
        {numSelected > 0 ? (
          <>
            <Tooltip title="Batch Edit">
              {loading ? (
                <CircularProgress />
              ) : (
                <IconButton aria-label="batch edit" onClick={batchEditSelection}>
                  <EditIcon />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="delete" onClick={deleteSelection}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <BatchEditDialog open={open} fields={fields} handleClose={handleClose} />
    </>
  )
}

interface BatchEdit {
  [key: string]: any
}

const BatchEditDialog: React.FC<{
  open: boolean
  fields: string[]
  handleClose: (x: BatchEdit) => void
}> = ({open, fields, handleClose}) => {
  const classes = useStyles()
  const [selectedField, setSelectedField] = React.useState<string>('')
  const [value, setValue] = React.useState<string>('')
  const [jsonError, setJsonError] = React.useState<Error | null>(null)

  const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setSelectedField(event.target.value as string)
  }

  const handleNewValue = (event: React.ChangeEvent<{value: unknown}>) => {
    setValue(event.target.value as string)
  }

  React.useEffect(() => {
    if (!value) {
      setJsonError(null)
      return
    }

    try {
      JSON.parse(value)
      setJsonError(null)
    } catch (error) {
      setJsonError(error)
    }
  }, [value])

  const resetState = () => {
    setValue('')
    setSelectedField('')
  }

  const canSubmit = () => value && selectedField && !jsonError

  return (
    <Dialog open={open} onEnter={resetState} onClose={handleClose}>
      <DialogTitle>Edit selected rows</DialogTitle>
      <DialogContent>
        <form className={classes.container}>
          <FormControl className={classes.formControl}>
            <InputLabel id="field-select-label">DB Field</InputLabel>
            <Select labelId="field-select-label" value={selectedField} onChange={handleChange} input={<Input />}>
              {fields.map((field) => (
                <MenuItem key={field} value={field}>
                  {field}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField label="New value" value={value} onChange={handleNewValue} />
          </FormControl>
        </form>
        {jsonError && <span style={{color: 'red'}}>{jsonError.toString()}</span>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose({})} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!canSubmit()}
          onClick={() => handleClose({[selectedField]: JSON.parse(value)})}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
    },
  }),
)

const QueryTable: React.FC<{
  results: NodeItem[]
  fields: string[]
  setResults: React.Dispatch<React.SetStateAction<NodeItem[]>>
}> = ({results, fields, setResults}) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('title')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(true)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const headCells: HeadCell[] = fields.map((fieldName) => {
    return {id: fieldName, numeric: false, disablePadding: false, label: fieldName}
  })

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = results.map((node) => node._key)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          clearSelection={() => setSelected([])}
          selectedKeys={selected}
          fields={fields}
          setResults={setResults}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={results.length}
            />
            <TableBody>
              {stableSort<NodeItem>(results, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((node, index) => {
                  const isItemSelected = isSelected(node._key)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, node._key)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={node._key}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{'aria-labelledby': labelId}} />
                      </TableCell>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id}>
                          {headCell.id === '_key' ? (
                            <a href={'/item/' + node[headCell.id]}>{node[headCell.id]?.toString()}</a>
                          ) : (
                            node[headCell.id] && node[headCell.id].toString()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 200, 300, 400, 500]}
          component="div"
          count={results.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
    </div>
  )
}

export default QueryTable
