import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {
  faAddressBook,
  faBook,
  faChalkboardTeacher,
  faComment,
  faHeading,
  faHome,
  faImage,
  faLock,
  faParagraph,
  faPuzzlePiece,
  faSign,
  faSitemap,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import {NodeItem, TemplateField} from '../data'

export interface LinkTemplate {
  label: string
  passiveLabel: string
  key: string
  icon: IconDefinition
  fields: TemplateField[]
  targetMatcher: (node: NodeItem) => boolean
  groupKey?: string
}

export const linkTemplates: LinkTemplate[] = [
  {
    passiveLabel: '{} is a member',
    label: 'Member of',
    key: 'member_of',
    fields: [],
    targetMatcher: ({type}) => type === 'user',
    icon: faUser,
  },
  {
    passiveLabel: '{} has access',
    label: 'Access to',
    key: 'access_to',
    fields: [
      {label: 'Status', key: 'status', type: 'string'},
      {label: 'Roles', key: 'roles', type: 'string', array: true},
      {label: 'Notes', key: 'notes', type: 'string'},
    ],
    targetMatcher: ({type}) => type === 'user',
    icon: faLock,
  },
  {
    passiveLabel: '{} is related',
    label: 'Related to',
    key: 'related',
    fields: [],
    targetMatcher: (_) => true,
    icon: faSign,
  },
  {
    passiveLabel: 'illustrated by {}',
    label: 'Illustrates',
    key: 'illustrates',
    fields: [],
    targetMatcher: (_) => true,
    icon: faImage,
  },
  {
    passiveLabel: 'taught by {}',
    label: 'Teaches',
    key: 'teaches',
    fields: [],
    targetMatcher: (_) => true,
    icon: faChalkboardTeacher,
  },
  {
    passiveLabel: '{} is a source',
    label: 'Source for',
    key: 'source',
    fields: [],
    targetMatcher: (_) => true,
    icon: faBook,
  },
  {
    passiveLabel: '{} is a contact',
    label: 'Contact for',
    key: 'contact_for',
    fields: [],
    targetMatcher: (_) => true,
    icon: faAddressBook,
  },
  {
    passiveLabel: 'defined by {}',
    label: 'Defines',
    groupKey: 'Descriptions',
    key: 'defines',
    fields: [],
    targetMatcher: (_) => true,
    icon: faHeading,
  },
  {
    passiveLabel: 'described by {}',
    label: 'Describes',
    groupKey: 'Descriptions',
    key: 'describes',
    fields: [],
    targetMatcher: (_) => true,
    icon: faParagraph,
  },
  {
    passiveLabel: '{} describes in detail',
    label: 'Details',
    groupKey: 'Descriptions',
    key: 'details',
    fields: [],
    targetMatcher: (_) => true,
    icon: faBook,
  },
  {
    passiveLabel: '{} is a part',
    label: 'Part of',
    key: 'part_of',
    fields: [],
    targetMatcher: (_) => true,
    icon: faPuzzlePiece,
  },
  {
    passiveLabel: 'organized by {}',
    label: 'Organizes',
    key: 'organizes',
    fields: [],
    targetMatcher: (_) => true,
    icon: faSitemap,
  },
  {
    passiveLabel: 'is located at {}',
    label: 'Located at',
    key: 'located_at',
    fields: [],
    targetMatcher: (_) => true,
    icon: faHome,
  },
  {
    passiveLabel: 'commented with {}',
    label: 'Comments',
    key: 'comments',
    fields: [],
    targetMatcher: (_) => true,
    icon: faComment,
  },
]
