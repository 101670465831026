import {definition as faVideo} from '@fortawesome/free-solid-svg-icons/faVideo'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import {api} from '../Api'
import {ContentItemWithPicture} from '../ContentItem'
import {NodeItem, Template} from '../data'
import {ellipsize, ifVal} from '../utils'
import {renderPageWithEmbed} from './template_fields'

const renderVideoItem: Template<VideoItem>['renderItem'] = (props) => (
  <ContentItemWithPicture picture={(props.node as VideoItem).thumbnail} {...props}>
    <Typography variant="subtitle2">{props.node.title}</Typography>
    <Typography color="textSecondary" variant="body2">
      {ellipsize(props.node.description, 100)}
    </Typography>
  </ContentItemWithPicture>
)

const renderVideoPage: Template<VideoItem>['renderPage'] = ({node, setNode, isEditing, notifyChange, relatedNodes}) => (
  <>
    <Container style={{marginBottom: '2rem'}}>
      <TextField fullWidth multiline value={node.description} label="Description" />
    </Container>
    {
      ifVal(
        node.url.match(/vimeo.com\/(\d+)/),
        (match) => (
          <Container>
            <iframe
              title={node.title}
              style={{width: '100%', height: '400px'}}
              src={'https://player.vimeo.com/video/' + match[1]}
              frameBorder={0}
            />
          </Container>
        ),
        () => renderPageWithEmbed({node, setNode, isEditing, notifyChange, relatedNodes}),
      )!
    }
  </>
)

const videoTemplate: Template<VideoItem> = {
  label: 'Video',
  type: 'video',
  title: 'Add Video',
  icon: faVideo,
  renderItem: renderVideoItem,
  renderPage: renderVideoPage,
  parseUrl: (url) =>
    url.match(/^(https:\/\/(www\.)?youtube\.com\/.+|https:\/\/(www\.)?vimeo.com\/(\d+))/i)
      ? api.preview(url).then((preview) => ({
          title: preview.title,
          description: preview.description,
          thumbnail: preview.image,
          url,
        }))
      : null,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Description', key: 'description', type: 'string'},
    {label: 'URL', key: 'url', type: 'string'},
    {label: 'Thumbnail URL', key: 'thumbnail', type: 'string'},
  ],
}
export default videoTemplate

export interface VideoItem extends NodeItem {
  type: 'video'
  url: string
  description: string
  thumbnail: string
}
