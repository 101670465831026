import {faCaretDown, faCaretRight, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import React, {MouseEvent, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {api} from './Api'
import {iconForNode, NodeConnection, NodeItem, NodeLink} from './data'

export const Tree: React.FC<{root: NodeItem}> = ({root}) => {
  const [expanded, setExpanded] = useState(false)
  const [children, setChildren] = useState<NodeConnection<NodeItem, NodeLink>[] | null>(null)

  const history = useHistory()

  useEffect(() => {
    if (expanded) api.getConnectionsTo(root, {}).then(setChildren)
    else setChildren(null)
  }, [root, expanded])

  return (
    <div>
      <TreeItem
        expanded={expanded}
        onClick={() => history.push(`/item/${root._key}`)}
        expand={() => setExpanded((e) => !e)}
        node={root}
      />
      <div style={{marginLeft: '2rem'}}>
        {children && children.map((c) => <Tree key={c.link._key} root={c.node} />)}
        {children && children.length < 1 && <Typography variant="body2">No Pages Inside</Typography>}
      </div>
    </div>
  )
}

export const TreeItem: React.FC<{
  onClick: () => void
  expand: () => void
  expanded: boolean
  node: NodeItem
  link?: NodeLink
}> = ({node, expanded, onClick, expand}) => {
  const handleExpandClick = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    expand()
  }

  return (
    <div className="tree-item" onClick={onClick}>
      <IconButton size="small" onClick={handleExpandClick}>
        <FontAwesomeIcon fixedWidth size="sm" icon={expanded ? faCaretDown : faCaretRight} />
      </IconButton>
      <FontAwesomeIcon size="xs" className="margin-right" icon={iconForNode(node) || faQuestionCircle} />
      <Typography variant="body2">{node.title}</Typography>
    </div>
  )
}
