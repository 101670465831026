import {definition as faParagraph} from '@fortawesome/free-solid-svg-icons/faParagraph'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import ReactTimeago from 'react-timeago'

import {ContentItem} from '../ContentItem'
import {NodeItem, Template} from '../data'
import {ellipsize} from '../utils'

const renderTextPage: Template<TextItem>['renderPage'] = ({node, isEditing}) => (
  <Container>
    {isEditing ? <TextField label="Content" multiline fullWidth value={node.content} /> : node.content}
  </Container>
)

export const renderTextItem: Template<TextItem>['renderItem'] = (props) => (
  <ContentItem {...props}>
    {props.node.title && <Typography variant="subtitle2">{props.node.title}</Typography>}
    <Typography variant="body2">{ellipsize(props.node.content)}</Typography>
    {props.link && props.link.type === 'comments' && (
      <Typography variant="caption">
        {props.node.owner} <ReactTimeago date={props.node.created_at} />
      </Typography>
    )}
  </ContentItem>
)

const textTemplate: Template<TextItem> = {
  label: 'Text',
  type: 'text',
  title: 'Add Text',
  icon: faParagraph,
  renderItem: renderTextItem,
  renderPage: renderTextPage,
  fields: [
    {label: 'Content', key: 'content', type: 'string', multiline: true},
    {label: 'Title (optional)', key: 'title', type: 'string'},
  ],
}
export default textTemplate

export interface TextItem extends NodeItem {
  type: 'text'
  content: string
}
