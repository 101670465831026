import {faUser} from '@fortawesome/free-solid-svg-icons'

import {NodeItem, Template, UnsavedNodeItem} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

export interface PersonItem extends NodeItem {
  type: 'person'
  phone: string
  email: string
  position: string
  bookmarks: string[]
}

const personTemplate: Template<PersonItem> = {
  label: 'Person',
  type: 'person',
  title: 'Add Person',
  icon: faUser,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  beforeCreate: async (item) => {
    if (!item.email) throw new Error('Email is required')
    item._key = item.email
  },
  fields: [
    {label: 'Name', key: 'title', type: 'string'},
    {label: 'Email', key: 'email', type: 'string'},
    // {label: 'Contact Person', key: 'contact_for', type: 'link'},
    // {label: 'Company', key: 'located_at', type: 'link'},
    {label: 'Client', key: 'located_at', type: 'link', linkReversed: true},
    {label: 'Freelancer', key: 'freelancer', type: 'boolean'},
    {label: 'Phone', key: 'phone', type: 'string'},
    // {label: 'Known via', key: 'related', type: 'link'},
    {label: 'Position', key: 'position', type: 'string'},
    {label: 'Database Roles', key: 'roles', type: 'string', array: true},
    {label: 'Notes', key: 'describes', type: 'richtext'},
  ],
}
export default personTemplate
export const isPersonItem = (node: UnsavedNodeItem): node is PersonItem => node.type === 'person'
