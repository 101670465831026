import {definition as faWrench} from '@fortawesome/free-solid-svg-icons/faWrench'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const toolTemplate: Template<ToolItem> = {
  label: 'Tool',
  type: 'tool',
  title: 'Register Tool',
  icon: faWrench,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Only Internal Use', key: 'internalOnly', type: 'boolean'},
    {label: 'Roles', key: 'roles', type: 'string', array: true},
    {label: 'Guide', key: 'guide', type: 'link'},
    {label: 'Password', key: 'password', type: 'secret'},
  ],
}
export default toolTemplate

export interface ToolItem extends NodeItem {
  title: string
  internalOnly: boolean
  roles: string[]
  guide: string
}
