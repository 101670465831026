import {faCalculator} from '@fortawesome/free-solid-svg-icons'

import {NodeItem, Template} from '../data'
import GoogleDocs from '../GoogleDocs'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const googleSheetTemplate: Template<GoogleSheetItem> = {
  label: 'Google Sheet',
  type: 'google_sheet',
  title: 'Create Excel/Sheets',
  icon: faCalculator,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'URL', key: 'url', type: 'string', hidden: true},
  ],
  parseUrl: (url) => (url.match('^https://docs.google.com/spreadsheets/') ? Promise.resolve({url}) : null),
  beforeCreate: async (item) => {
    if (!item.url) item.url = await GoogleDocs.createSheets(item.title)
  },
}
export default googleSheetTemplate

export interface GoogleSheetItem extends NodeItem {
  url: string
}
