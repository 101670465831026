import {faMeetup} from '@fortawesome/free-brands-svg-icons'

import {NodeItem, Template} from '../data'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const miroTemplate: Template<MiroItem> = {
  label: 'Miro Board',
  type: 'miro_board',
  title: 'Create Miro Board',
  icon: faMeetup,
  renderItem: defaultRenderItem,
  renderPage: defaultRenderPage,
  parseUrl: (url) => (url.match('^https://miro.com/app/board/') ? Promise.resolve({url}) : null),
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'URL', key: 'url', type: 'string', hidden: true},
  ],
}
export default miroTemplate

export interface MiroItem extends NodeItem {
  url: string
}
