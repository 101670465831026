import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import {Observable} from 'rxjs'
import {auditTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators'
import {useEventCallback} from 'rxjs-hooks'

import {api} from './Api'
import {iconForNode, NodeItem} from './data'
import FilterList from './FilterList'

const Search: React.FC<{autoFocus?: boolean; onSelect: (item: NodeItem) => void}> = ({autoFocus, onSelect}) => {
  const [typeCallback, results] = useEventCallback<string, NodeItem[]>(
    (event$: Observable<string>) =>
      event$.pipe(
        map((str) => (str.length > 0 ? 'prefix:' + str : str)),
        auditTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => (searchString.length < 1 ? [] : api.getFullTextSearch('title', searchString))),
      ),
    [],
  )

  const recent: NodeItem[] = []

  return (
    <>
      <FilterList
        items={results}
        iconCb={(i) => iconForNode(i) || faQuestionCircle}
        titleCb={(i) => i.title}
        label="Search ..."
        onChange={typeCallback}
        autoFocus={autoFocus}
        onSelect={onSelect}
        keyCb={(i) => i._id}
      />

      <Typography variant="overline">RECENT</Typography>
      {recent.map((i) => (
        <ListItem key={i._key} button onClick={(_) => onSelect(i)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={iconForNode(i) || faQuestionCircle} />
          </ListItemIcon>
          {i.title}
        </ListItem>
      ))}
    </>
  )
}

export default Search
