import {faEdit, faTrash, faUnlink} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {api} from './Api'
import {Notifications} from './App'
import {NodeConnection, NodeItem, NodeLink, templateForNode} from './data'
import {ifVal} from './utils'

export type ContentItemProps<T extends NodeItem> = {
  onDelete?: (c: NodeConnection<NodeItem, NodeLink>) => void
  onUpdateLink?: (newLink: NodeLink) => void
  onClick?: () => void
  node: T
  link?: NodeLink
}

type ContentItemWithPictureProps<T extends NodeItem> = ContentItemProps<T> & {picture: string; children: ReactNode}

export const ContentItemWithPicture: <T extends NodeItem>(
  props: ContentItemWithPictureProps<T> & {children: ReactNode},
) => React.ReactElement = ({children, picture, ...props}) => {
  return (
    <ContentItem ownContent {...props}>
      <CardActionArea>
        <CardMedia style={{height: '140px'}} image={picture}></CardMedia>
        <CardContent>{children}</CardContent>
      </CardActionArea>
    </ContentItem>
  )
}

export const ContentItem: <T extends NodeItem>(
  props: ContentItemProps<T> & {ownContent?: boolean; children: ReactNode},
) => React.ReactElement = ({onClick, onUpdateLink, children, ownContent, node, link, onDelete}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const handleClose = () => setAnchorEl(null)

  const history = useHistory()

  const deleteNode = async () =>
    api.safeDo(async () => {
      await api.deleteNode(node)
      if (onDelete) onDelete({node, link: link!})
    })

  const unlinkNode = async () =>
    api.safeDo(async () => {
      await api.deleteLink(link!)
      if (onDelete) onDelete({node, link: link!})
    })

  const editLink = async () =>
    api.safeDo(async () => {
      const newType = prompt('Type?')
      if (!newType) return
      // TODO should be an immutable update
      link!.type = newType
      await api.updateLink(link!, ['type'])
      onUpdateLink!(link!)
    })

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  const showNotification = useContext(Notifications)
  const longPressCbs = useLongPress(() => {
    ifVal(
      templateForNode(node).copyContent,
      (cb) => cb(node),
      () => {
        copyText(node.title)
        showNotification('Content copied to clipboard')
      },
    )
  })

  const handleClick = () => (onClick ? onClick() : history.push(`/item/${node._key}`))

  return (
    <>
      <Card
        {...longPressCbs}
        onClick={handleClick}
        onContextMenu={(e) => {
          e.preventDefault()
          if (onDelete) setAnchorEl(e.currentTarget)
        }}
        className="content-item"
      >
        {ownContent ? (
          children
        ) : (
          <CardActionArea>
            <CardContent>{children}</CardContent>
          </CardActionArea>
        )}
      </Card>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableRestoreFocus
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        {link && onUpdateLink && (
          <ListItem button onClick={editLink}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faEdit} />
            </ListItemIcon>{' '}
            <ListItemText primary="Change Link Type" />
          </ListItem>
        )}
        {link && onDelete && (
          <ListItem button onClick={unlinkNode}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUnlink} />
            </ListItemIcon>{' '}
            <ListItemText primary="Unlink" />
          </ListItem>
        )}
        {onDelete && (
          <ListItem button onClick={deleteNode}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faTrash} />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ListItem>
        )}
      </Menu>
    </>
  )
}

const useLongPress = (callback: () => void, duration = 300) => {
  const [longPressStarted, setLongPressStarted] = useState(false)
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (longPressStarted) setTimerId(setTimeout(callback, duration))
    else if (timerId) clearTimeout(timerId)

    return () => {
      if (timerId) clearTimeout(timerId)
    }
    // we don't want to recreate the timerId recursively, so it can't be a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longPressStarted])

  return {
    onMouseDown: () => setLongPressStarted(true),
    onMouseUp: () => setLongPressStarted(false),
    onMouseLeave: () => setLongPressStarted(false),
    onTouchStart: () => setLongPressStarted(true),
    onTouchEnd: () => setLongPressStarted(false),
  }
}
