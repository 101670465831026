import {faNeos} from '@fortawesome/free-brands-svg-icons'

import {NodeItem, Template} from '../data'
import {ifVal} from '../utils'
import {defaultRenderItem, renderPageWithEmbed} from './template_fields'

const notionTemplate: Template<NotionItem> = {
  label: 'Notion Page',
  type: 'notion_page',
  title: 'Create Notion Page',
  icon: faNeos,
  renderItem: defaultRenderItem,
  renderPage: renderPageWithEmbed,
  parseUrl: (url) =>
    ifVal(url.match(/^https:\/\/www\.notion\.so\/4ed1\/(.+)-[A-Za-z0-9]+/), (match) =>
      Promise.resolve({url, title: match[1].replace('-', ' ')}),
    ),
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'URL', key: 'url', type: 'string', hidden: true},
  ],
}

export default notionTemplate

export interface NotionItem extends NodeItem {
  type: 'notion'
  url: string
}
