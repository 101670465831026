import {definition as faLink} from '@fortawesome/free-solid-svg-icons/faLink'
import {definition as faListAlt} from '@fortawesome/free-solid-svg-icons/faListAlt'
import {definition as faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React, {useEffect, useState} from 'react'

import {api} from '../Api'
import ConnectButton from '../ConnectButton'
import {ContentItem, ContentItemProps} from '../ContentItem'
import {iconForNode, NodeConnection, NodeItem, NodeLink, Template} from '../data'

const renderCollectionItem: Template<CollectionItem>['renderItem'] = (props) => <CollectionItemView {...props} />

const CollectionItemView: React.FC<ContentItemProps<CollectionItem>> = (props) => {
  const [connections, setConnections] = useState<NodeConnection<NodeItem, NodeLink>[] | null>(null)

  useEffect(() => {
    api.getConnectionsTo(props.node, {}).then(setConnections)
  }, [props.node])

  return (
    <ContentItem {...props}>
      <Typography variant="subtitle2" gutterBottom>
        <FontAwesomeIcon style={{marginRight: '0.5rem'}} icon={iconForNode(props.node)!} />
        {props.node.title}
      </Typography>
      {connections ? (
        connections.map((connection) => (
          <div key={connection.link._key} style={{borderTop: '1px solid #ddd', padding: '0.5rem 0'}}>
            <Typography variant="body2">
              <FontAwesomeIcon
                style={{marginRight: '0.5rem'}}
                fixedWidth
                icon={iconForNode(connection.node) || faQuestionCircle}
              />
              {connection.node.title}
            </Typography>
          </div>
        ))
      ) : (
        <CircularProgress />
      )}
    </ContentItem>
  )
}

const methodIcon = faListAlt

const renderCollectionPage: Template<CollectionItem>['renderPage'] = ({node}) => (
  <Container>
    <ConnectButton
      showSearch={false}
      node={node}
      button={(onClick) => (
        <ButtonGroup size="small">
          <Button disabled>Add</Button>
          <Button
            onClick={(e) => onClick(e.currentTarget, 'part_of', 'method')}
            startIcon={<FontAwesomeIcon size="xs" icon={methodIcon} />}
          >
            Method
          </Button>
          <Button
            onClick={(e) => onClick(e.currentTarget, 'part_of')}
            startIcon={<FontAwesomeIcon size="xs" icon={faLink} />}
          >
            Other ...
          </Button>
        </ButtonGroup>
      )}
    />
  </Container>
)

const collectionTemplate: Template<CollectionItem> = {
  label: 'Collection',
  type: 'collection',
  title: 'Add Collection',
  icon: methodIcon,
  renderItem: renderCollectionItem,
  renderPage: renderCollectionPage,
  fields: [{label: 'Title', key: 'title', type: 'string'}],
}
export default collectionTemplate

export interface CollectionItem extends NodeItem {}
