import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import React, {KeyboardEvent, useCallback, useEffect, useState} from 'react'

type Props<T> = {
  items: T[]
  onSelect: (item: T) => void
  label: string
  keyCb: (item: T) => string
  iconCb: (item: T) => IconDefinition
  titleCb: (item: T) => string
  onChange?: (text: string) => void
  onPaste?: (event: React.ClipboardEvent) => Promise<string>
  autoFocus?: boolean
  disabled?: boolean
  maxItems?: number
  defaultFilterString?: string
  leaveFilterOnSelect?: boolean
}

const FilterList = <T extends object>({
  defaultFilterString,
  autoFocus,
  disabled,
  items,
  maxItems,
  titleCb,
  keyCb,
  onChange,
  onPaste,
  iconCb,
  onSelect,
  label,
  leaveFilterOnSelect,
}: Props<T>) => {
  const [filterString, setFilterString] = useState(defaultFilterString || '')
  const [activeIndex, setActiveIndex] = useState(-1)
  const [filteredList, setFilteredList] = useState(items)
  const [hasFocus, setHasFocus] = useState(false)

  const filterList = useCallback(
    (filter: string) => (i: T) => filter.length < 1 || titleCb(i).toLowerCase().includes(filter.toLowerCase()),
    [titleCb],
  )

  useEffect(() => {
    const filtered = items.filter(filterList(filterString))
    setFilteredList(maxItems ? filtered.slice(0, maxItems) : filtered)
    setActiveIndex((i) => (filtered.length < 1 || !hasFocus ? -1 : Math.max(0, Math.min(i, filtered.length - 1))))
  }, [items, maxItems, hasFocus, filterList, filterString])

  const updateFilter = (val: string) => {
    const filtered = items.filter(filterList(val))
    setFilterString(val)
    setFilteredList(maxItems ? filtered.slice(0, maxItems) : filtered)
    setActiveIndex((i) => (filtered.length < 1 ? -1 : Math.max(0, Math.min(i, filtered.length - 1))))
  }

  const select = (item: T) => {
    setActiveIndex(0)
    onSelect(item)
    if (!leaveFilterOnSelect) updateFilter(titleCb(item))
  }

  const inputKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        setActiveIndex((i) => Math.min(i + 1, filteredList.length - 1))
        break
      case 'ArrowUp':
        setActiveIndex((i) => Math.max(i - 1, 0))
        break
      case 'Enter':
        if (filteredList.length > 0) {
          select(filteredList[activeIndex])
        }
        break
      default:
        return
    }
    e.preventDefault()
  }

  const onBlur = () => {
    setHasFocus(false)
    setActiveIndex(-1)
  }
  const onFocus = () => {
    setHasFocus(true)
    if (activeIndex < 0) setActiveIndex(0)
  }

  const handlePaste = async (event: React.ClipboardEvent) => {
    if (onPaste) {
      const newText = await onPaste(event)
      updateFilter(newText)
    }
  }

  return (
    <div>
      <TextField
        fullWidth
        autoFocus={autoFocus}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        value={filterString}
        InputProps={{onKeyDown: inputKeyDown}}
        label={label}
        onPaste={handlePaste}
        onChange={(e) => {
          onChange && onChange(e.target.value)
          updateFilter(e.target.value)
        }}
      />

      {filteredList.map((i, index) => (
        <ListItem
          disabled={disabled}
          tabIndex={-1}
          selected={index === activeIndex}
          key={keyCb(i)}
          button
          onClick={(_) => select(i)}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={iconCb(i)} />
          </ListItemIcon>
          <ListItemText>{titleCb(i)}</ListItemText>
        </ListItem>
      ))}
    </div>
  )
}

export default FilterList
