import {faSlideshare} from '@fortawesome/free-brands-svg-icons'
import {NodeItem, RenderPageProps, Template} from 'data'
import GoogleDocs from 'GoogleDocs'
import {PresentationPage} from 'PresentationPage'
import React from 'react'

import {defaultRenderItem} from './template_fields'

const googleSlidesTemplate: Template<SlidesItem> = {
  label: 'Google Slides',
  type: 'google_slides',
  title: 'Create Presentation/Slides',
  icon: faSlideshare,
  renderItem: defaultRenderItem,
  renderPage: (props: RenderPageProps<SlidesItem>) => <PresentationPage {...props} />,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'URL', key: 'url', type: 'string', hidden: true},
  ],
  parseUrl: (url) => (url.match('^https://docs.google.com/presentation/') ? Promise.resolve({url}) : null),
  beforeCreate: async (item) => {
    if (!item.url) item.url = await GoogleDocs.createSlides(item.title)
  },
}
export default googleSlidesTemplate

export interface SlidesItem extends NodeItem {}
