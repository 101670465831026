import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {SlidesItem} from 'data/google_slides'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {api} from './Api'
import {User, ViewContextProvider} from './App'
import {
  defaultValueForField,
  NodeItem,
  Template,
  TemplateField,
  templateForType,
  templates,
  UnsavedNodeItem,
} from './data'
import {smallEditorForType} from './data/template_fields'
import FilterList from './FilterList'
import {googleSlideTemplateType} from './GoogleDocs'
import {idForGoogleSlides} from './PresentationPage'
import {ifVal} from './utils'

export const AddEntryPage = () => {
  const history = useHistory()
  const onCreated = (data: any) => history.push(`/item/${data._key}`)
  return (
    <div style={{maxWidth: '800px', margin: '3rem'}}>
      <Typography gutterBottom variant="h4">
        Add Entry
      </Typography>
      <AddEntry onCreate={onCreated} />
    </div>
  )
}

type NodeData = {[id: string]: any}

type AddEntryProps = {maxItems?: number; autoFocus?: boolean; onCreate: (data: any) => void}

export const AddEntry: React.FC<AddEntryProps> = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template<NodeItem> | null>(null)
  return <InnerAddEntry {...props} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
}

export const InnerAddEntry: React.FC<
  AddEntryProps & {
    hideFilter?: boolean
    selectedTemplate: Template<NodeItem> | null
    setSelectedTemplate: (t: Template<NodeItem> | null) => void
  }
> = ({hideFilter, onCreate, autoFocus, maxItems, selectedTemplate, setSelectedTemplate}) => {
  const [node, setNode] = useState<NodeData | null>(null)
  const [loading, setLoading] = useState(false)

  const firstInputRef = useRef<HTMLInputElement>()
  const [viewContext] = useContext(ViewContextProvider)
  const [user] = useContext(User)

  const create = async () => {
    const finalNode: UnsavedNodeItem = {
      ...selectedTemplate!.fields
        .filter((field) => field.type !== 'link')
        .reduce<NodeData>((res, field) => {
          res[field.key] = node![field.key]
          return res
        }, {}),
      title: node!.title,
      type: selectedTemplate!.type,
      owner: user._key,
    }

    try {
      setLoading(true)
      if (selectedTemplate!.beforeCreate) await selectedTemplate!.beforeCreate(finalNode)

      const res = await api.create(finalNode)
      finalNode._id = res._id
      finalNode._key = res._key

      await Promise.all(
        selectedTemplate!.fields
          .filter((field) => field.type === 'link' && node![field.key])
          .map((field) =>
            api.link(
              field.linkReversed
                ? {from: res, to: node![field.key], link: {type: field.key}}
                : {from: node![field.key], to: res, link: {type: field.key}},
            ),
          ),
      )

      // move setLoading(false) up in case onCreate wants to navigate away
      setLoading(false)
      onCreate(finalNode)
    } catch (error) {
      api.handleError(error)
      setLoading(false)
    }
  }

  const checkLink = async (event: React.ClipboardEvent): Promise<string> => {
    if (!event) return ''

    if (
      viewContext &&
      viewContext.item.type === 'google_slides' &&
      event.clipboardData.types.includes(googleSlideTemplateType)
    ) {
      const data = event.clipboardData.getData(googleSlideTemplateType)
      const slide = JSON.parse(data)
      const content = JSON.parse(slide.data)
      // this appears to usually contain the content of the title field, but no guarantees
      const title = ifVal<any, string>(
        content.commands.find((command: any) => command[0] === 15),
        (command: any) => command[4],
        () => '',
      )

      const template = templateForType('slide_template')
      setSelectedTemplate(template)
      // setNode({content: data, title})
      setNode({
        title: title,
        presentationTitle: viewContext.item.title,
        presentationId: idForGoogleSlides((viewContext.item as SlidesItem).url),
        slideId: content.contentIds[0],
      })
      return template.label
    }

    const plainText = event.clipboardData.getData('text/plain')
    const matching = (
      await Promise.all(
        Object.values(templates)
          .filter((t) => t.parseUrl)
          .map((template) => ifVal(template.parseUrl!(plainText), (p) => p.then((data) => ({template, data})))),
      )
    ).find((result) => result && result.data)

    if (matching) {
      setNode(matching.data)
      setSelectedTemplate(matching.template)
      return plainText
    }

    if (plainText.match(/https?:\/\//)) {
      const p = await api.preview(plainText)
      const template = templates['website']
      setNode({
        title: p.title,
        description: p.description,
        url: plainText,
        comment: '',
      })
      setSelectedTemplate(template)
      return plainText
    }

    return plainText
  }

  const nodeTypeSelected = (template: Template<NodeItem>) => {
    setSelectedTemplate(template)
  }

  useEffect(() => {
    if (selectedTemplate) {
      setNode(
        selectedTemplate.fields.reduce<{[key: string]: any}>((obj, field: TemplateField) => {
          obj[field.key] = defaultValueForField(field)
          return obj
        }, {}),
      )
      setTimeout(() => firstInputRef.current && firstInputRef.current!.focus())
    }
  }, [selectedTemplate])

  const checkCreateKeyPressed = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.ctrlKey) create()
  }

  const formFieldsElements = (fields: TemplateField[]) => (
    <>
      {fields
        .filter((field) => !field.hidden)
        .map((field, index) => (
          <Grid item key={field.key}>
            {smallEditorForType({
              field,
              checkCreateKeyPressed,
              inputRef: index === 0 ? firstInputRef : undefined,
              value: node![field.key],
              onChange: (val) => setNode((n) => ({...n, [field.key]: val})),
              loading,
            })}
          </Grid>
        ))}
      <Grid item>
        <Button onClick={create} variant="outlined" color="primary">
          Create (Ctrl+Enter)
        </Button>
      </Grid>
    </>
  )

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        {!hideFilter && (
          <FilterList
            maxItems={maxItems}
            autoFocus={autoFocus}
            titleCb={(t) => t.title}
            keyCb={(t) => t.type}
            iconCb={(t) => t.icon}
            items={Object.values(templates)}
            disabled={loading}
            label="Type to create or paste URL"
            onPaste={checkLink}
            onSelect={nodeTypeSelected}
          />
        )}

        {loading && <CircularProgress />}

        {selectedTemplate && node && (
          <div style={{padding: '1rem 0'}}>
            <FontAwesomeIcon icon={selectedTemplate.icon} style={{marginRight: '1rem'}} />
            Add {selectedTemplate.label}
          </div>
        )}
      </Grid>

      {selectedTemplate && selectedTemplate.fields && node && formFieldsElements(selectedTemplate.fields)}
    </Grid>
  )
}
