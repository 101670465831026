import {faCalendar} from '@fortawesome/free-solid-svg-icons'
import Button from '@material-ui/core/Button'
import {NodeItem, RenderPageProps, Template} from 'data'
import {EditorState} from 'draft-js'
import {stateToHTML} from 'draft-js-export-html'
import React from 'react'
import ReactDOM from 'react-dom'

import {Agenda, Row} from './agenda_builder'
import {defaultRenderItem, defaultRenderPage} from './template_fields'

const renderAgendaPage = (props: RenderPageProps<AgendaItem>) => {
  const printExport = () => {
    const formatMinutes = (minutes: number) => {
      const hours = minutes / 60
      const mins = minutes % 60
      return (hours < 10 ? '0' : '') + Math.floor(hours) + ':' + (mins < 10 ? '0' : '') + mins
    }

    const stylesFor = (row: Row, columnIndex: number) => {
      const background = columnIndex === 0 ? agenda.colors[row.color]?.color : undefined
      // FIXME can certainly be improved
      const foreground =
        background &&
        (parseInt(background.substring(1, 3), 16) +
          parseInt(background.substring(3, 5), 16) +
          parseInt(background.substring(5, 7), 16)) /
          (3 * 255) <
          0.5
          ? '#ffffff'
          : '#000000'
      return {backgroundColor: background, color: foreground}
    }

    const container = document.createElement('div')
    const agenda = props.node.agenda
    ReactDOM.render(
      <div>
        <link rel="stylesheet" href={window.location.protocol + '//' + window.location.host + '/agenda_print.css'} />
        <h1>{props.node.title}</h1>
        <h3>{(props.node as any).subtitle}</h3>
        <div
          dangerouslySetInnerHTML={{__html: stateToHTML((props.node.intro as EditorState).getCurrentContent())}}
        ></div>
        <table>
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {agenda.columnLabels.map((label) => (
                <td key={label}>{label}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {agenda.rows.map((row, index) => {
              const start = agenda.rows.slice(0, index).reduce((sum, row) => sum + row.duration, agenda.start)
              return (
                <tr key={index}>
                  <td>{formatMinutes(start)}</td>
                  <td>{formatMinutes(start + row.duration)}</td>
                  <td>{formatMinutes(row.duration)}</td>
                  {Array.from({length: agenda.columnLabels.length}, (x, i) => (
                    <td key={i} style={stylesFor(row, i)}>
                      {row.columns[i] ?? ''}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="agenda-legend">
          {agenda.colors.map((color) => (
            <div key={color.color} style={{marginBottom: 8}}>
              <div
                style={{width: 18, height: 18, background: color.color, display: 'inline-block', marginRight: 8}}
              ></div>{' '}
              {color.label}
            </div>
          ))}
        </div>
        <img src="https://foreverday.one/logo.png" alt="logo" />
      </div>,
      container,
    )

    const win = window.open(undefined, props.node.title)
    if (win) {
      win.document.body.innerHTML = container.innerHTML
      win.addEventListener('load', () => setTimeout(() => win.print(), 1000))
    }
  }

  return (
    <div>
      <div style={{paddingLeft: '24px'}}>
        <Button variant="contained" color="primary" onClick={printExport}>
          Print/Export
        </Button>
      </div>
      {defaultRenderPage({...props, fullWidth: true})}
    </div>
  )
}

// TODO: Separate consts and computed values, so constants can be reused

const agendaTemplate: Template<AgendaItem> = {
  type: 'agenda',
  title: 'Add Agenda',
  icon: faCalendar,
  label: 'Agenda',
  fullWidthPage: true,
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Subtitle', key: 'subtitle', type: 'string', multiline: true},
    {label: 'Intro-Text', key: 'intro', type: 'richtext'},
    {label: 'Agenda', key: 'agenda', type: 'agenda'},
  ],
  renderItem: defaultRenderItem,
  renderPage: renderAgendaPage,
}
export default agendaTemplate

export interface AgendaItem extends NodeItem {
  type: 'agenda'
  subtitle: string
  intro: EditorState
  agenda: Agenda
}
