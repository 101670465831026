import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import {createStyles, fade, makeStyles, Theme} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import React, {createRef, useState} from 'react'
import {NavLink, useHistory} from 'react-router-dom'

import {AddDialog} from './AddDialog'
import {ViewContext} from './App'
import {useShortcut} from './shortcut'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    inheritColor: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    expand: {
      flexGrow: 1,
    },
  }),
)

export const Topbar: React.FC<{viewContext: ViewContext | null}> = ({viewContext}) => {
  const classes = useStyles()
  const [addDialogOpen, setAddDialogOpen] = useState(false)
  const handleDrawerOpen = () => {}
  const searchEl = createRef<any>()
  const addNew = () => {
    setAddDialogOpen(true)
  }
  const focusSearch = () => {
    searchEl.current.focus()
  }

  useShortcut('N', addNew)
  useShortcut('0', focusSearch)

  const history = useHistory()

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        onCreate={(data: any) => history.push(`/item/${data._key}`)}
        onClose={() => setAddDialogOpen(false)}
      />
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
            <MenuIcon />
          </IconButton>
          <NavLink to="/" style={{textDecoration: 'none', color: 'inherit'}}>
            <Typography variant="h6" noWrap>
              Knowledge
            </Typography>
          </NavLink>

          <div className={classes.expand} />

          <Button onClick={addNew} color="secondary" variant="contained">
            New Entry (Ctrl+Shift+n)
          </Button>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              inputRef={searchEl}
              placeholder="Search… (CTRL+0)"
              classes={{
                root: classes.inheritColor,
                input: classes.inputInput,
              }}
              inputProps={{'aria-label': 'search'}}
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}
