import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {User} from 'App'
import React, {useContext, useEffect, useMemo, useState} from 'react'

import {api} from './Api'
import {NodeConnection, NodeItem, NodeLink, renderItemForNode} from './data'

export const TodoItemList: React.FC = () => {
  const [user] = useContext(User)
  return NodeItemList({
    title: 'ToDo',
    filters: useMemo(() => ({type: 'missing_info', request_from: user._key}), [user._key]),
  })
}

export const BookmarkList: React.FC = () => {
  // const [user] = useContext(User)
  // eslint-disable-next-line no-constant-condition
  return true ? (
    <></>
  ) : (
    NodeItemList({
      title: 'Bookmarks',
      // TODO: query
      // query: `FOR d IN (DOCUMENT("documents", @userId).bookmarks ?: []) RETURN DOCUMENT("documents", d)`,
      filters: {},
    })
  )
}

export const DumpedItemList: React.FC = () => {
  // const [user] = useContext(User)
  // eslint-disable-next-line no-constant-condition
  return true ? (
    <></>
  ) : (
    NodeItemList({
      title: 'Dumped Items',
      filters: {},
      // TODO: query
      /*query: `FOR d IN documents
    FILTER d.owner == @userId
    AND LENGTH((FOR edge IN link
      FILTER edge._from == d._id OR edge._to == d._id
      RETURN 1)) < 1
    RETURN d`,*/
    })
  )
}

type NodeItemListProps = {
  title?: string
  onSelect?: (item: NodeItem) => void
  filters: {[field: string]: null | string | number}
  content?: (item: NodeItem[]) => JSX.Element
}

export const NodeItemList: React.FC<NodeItemListProps> = ({title, filters, content}) => {
  const [items, setItems] = useState<NodeItem[]>([])

  useEffect(() => {
    api.safeDo(() => api.getList(filters).then(setItems))
  }, [filters])

  const deleteItem = (c: NodeConnection<NodeItem, NodeLink>) =>
    setItems((items) => items.filter((i) => i._key !== c.node._key))

  return (
    <Grid container direction="column" spacing={1}>
      {title && (
        <Typography gutterBottom variant="h6">
          {title}
        </Typography>
      )}
      {(content && content(items)) ||
        items.map((node) => (
          <Grid item key={node._key}>
            {renderItemForNode({node, onDelete: deleteItem, key: node._key})}
          </Grid>
        ))}
    </Grid>
  )
}
