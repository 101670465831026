import {faMicrophone} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Fab from '@material-ui/core/Fab'
import TextField from '@material-ui/core/TextField'
import React, {useState} from 'react'

type Language = 'de-DE' | 'en-US'

export const QuickWrite: React.FC<{autoFocus?: boolean}> = ({autoFocus}) => {
  const [recording, setRecording] = useState(false)
  const [language, setLanguage] = useState<Language>('de-DE')
  const [text, setText] = useState('')

  const toggleRecording = () => {
    if (recording) {
      setRecording(false)
      AudioStreamer.stopRecording()
    } else {
      setRecording(true)
      AudioStreamer.initRecording(
        language,
        (data) => {
          console.log(data)
          if (
            data &&
            data.results &&
            data.results.length > 0 &&
            data.results[0].alternatives &&
            data.results[0].alternatives.length > 0 &&
            data.results[0].alternatives[0].transcript
          )
            setText((text) => text + data.results[0].alternatives[0].transcript)
        },
        (error) => {
          AudioStreamer.stopRecording()
          alert(error)
        },
      )
    }
  }

  return (
    <>
      <div style={{display: 'flex'}}>
        <div style={{flexGrow: 1, marginRight: '1rem'}}>
          <TextField
            autoFocus={autoFocus}
            onChange={(e) => setText(e.target.value)}
            multiline
            value={text}
            label="Type ..."
            fullWidth
          />
        </div>
        <div>
          <Fab onClick={toggleRecording} color={recording ? 'secondary' : 'primary'}>
            <FontAwesomeIcon size="lg" icon={faMicrophone} />
          </Fab>
        </div>
      </div>
      <div style={{marginTop: '1rem', display: 'flex', justifyContent: 'space-between'}}>
        <Button variant="outlined" color="primary" disabled={text.length < 1}>
          Create
        </Button>

        <ButtonGroup>
          <Button color={language === 'de-DE' ? 'primary' : 'default'} onClick={(_) => setLanguage('de-DE')}>
            DE
          </Button>
          <Button color={language === 'en-US' ? 'primary' : 'default'} onClick={(_) => setLanguage('en-US')}>
            EN
          </Button>
        </ButtonGroup>
      </div>
    </>
  )
}

declare var socket: any

let bufferSize = 2048,
  AudioContext: any,
  context: any,
  processor: any,
  input: any,
  globalStream: any

const constraints = {
  audio: true,
  video: false,
}

const AudioStreamer = {
  /**
   * @param {function} onData Callback to run on data each time it's received
   * @param {function} onError Callback to run on an error if one is emitted.
   */
  initRecording: (language: string, onData: (data: any) => void, onError: (error: any) => void) => {
    socket.emit('startGoogleCloudStream', {
      config: {
        encoding: 'LINEAR16',
        sampleRateHertz: 16000,
        languageCode: 'en-US',
        profanityFilter: false,
        enableWordTimeOffsets: true,
      },
      interimResults: true, // If you want interim results, set this to true
    })

    AudioContext = window.AudioContext //  || window.webkitAudioContext;
    context = new AudioContext()
    processor = context.createScriptProcessor(bufferSize, 1, 1)
    processor.connect(context.destination)
    context.resume()

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      globalStream = stream
      input = context.createMediaStreamSource(stream)
      input.connect(processor)

      processor.onaudioprocess = function (e: any) {
        microphoneProcess(e)
      }
    })

    // Bind the data handler callback
    if (onData) {
      socket.on('speechData', (data: any) => {
        onData(data)
      })
    }

    socket.on('googleCloudStreamError', (error: any) => {
      if (onError) {
        onError('error')
      }
      // We don't want to emit another end stream event
      closeAll()
    })
  },

  stopRecording: function () {
    socket.emit('endGoogleCloudStream', '')
    closeAll()
  },
}

export default AudioStreamer

// Helper functions
/**
 * Processes microphone data into a data stream
 *
 * @param {object} e Input from the microphone
 */
function microphoneProcess(e: any) {
  var left = e.inputBuffer.getChannelData(0)
  var left16 = convertFloat32ToInt16(left)
  socket.emit('binaryAudioData', left16)
}

/**
 * Converts a buffer from float32 to int16. Necessary for streaming.
 * sampleRateHertz of 1600.
 *
 * @param {object} buffer Buffer being converted
 */
function convertFloat32ToInt16(buffer: any) {
  let l = buffer.length
  let buf = new Int16Array(l / 3)

  while (l--) {
    if (l % 3 === 0) {
      buf[l / 3] = buffer[l] * 0xffff
    }
  }
  return buf.buffer
}

/**
 * Stops recording and closes everything down. Runs on error or on stop.
 */
function closeAll() {
  // Clear the listeners (prevents issue if opening and closing repeatedly)
  socket.off('speechData')
  socket.off('googleCloudStreamError')
  let tracks = globalStream ? globalStream.getTracks() : null
  let track = tracks ? tracks[0] : null
  if (track) {
    track.stop()
  }

  if (processor) {
    if (input) {
      try {
        input.disconnect(processor)
      } catch (error) {
        console.warn('Attempt to disconnect input failed.')
      }
    }
    processor.disconnect(context.destination)
  }
  if (context) {
    context.close().then(function () {
      input = null
      processor = null
      context = null
      AudioContext = null
    })
  }
}
