import {faEdit, faEye, faLink, faProjectDiagram, faQuestionCircle, faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {makeStyles} from '@material-ui/styles'
import {User} from 'App'
import clsx from 'clsx'
import {PersonItem} from 'data/person'
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import TimeAgo from 'react-timeago'

import {api} from './Api'
import ConnectButton from './ConnectButton'
import {
  groupKeyForLink,
  iconForNode,
  labelForLink,
  linkTemplateForKey,
  MemberOfLink,
  NodeConnection,
  NodeItem,
  NodeLink,
  renderItemForNode,
  renderPageForNode,
  templateForNode,
} from './data'
import Navigation from './Navigation'
import {useShortcut} from './shortcut'
import {ifVal} from './utils'

const groupBy = <T extends any>(array: T[], keyCb: (item: T) => string): {[id: string]: T[]} =>
  array.reduce((map: {[id: string]: T[]}, current: T) => {
    const key = keyCb(current)
    if (map[key]) map[key].push(current)
    else map[key] = [current]
    return map
  }, {})

const useStyles = makeStyles({
  avatar: {
    marginRight: '10px',
    fontSize: 8,
    width: 22,
    height: 22,
  },
  extraBottomSpacing: {
    marginBottom: '1rem',
  },
  usedInButton: {
    marginRight: '1rem',
  },
  leftIcon: {
    marginRight: '1rem',
  },
  floatingFooter: {
    position: 'fixed',
    right: '10%',
    bottom: '0',
    padding: '0 1rem',
    background: '#123E66',
    color: '#fff',
    borderRadius: '1rem 1rem 0 0',
    display: 'flex',
    zIndex: 99999,
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  floatingButtonText: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  floatingFooterModeButton: {
    background: '#0A2C46',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    borderTop: '5px solid transparent',
  },
  floatingFooterModeButtonActive: {
    borderTop: '5px solid #93BBF6',
  },
})

export const NodePageRoute: React.FC = () => {
  const {id} = useParams<{id: string}>()
  return id ? <NodePageForId id={id} /> : <div>please specify an id</div>
}

export const NodePageForId: React.FC<{id: string}> = ({id: key}) => {
  const [itemInfo, setItemInfo] = useState<{
    node: NodeItem
    related: NodeConnection<NodeItem, NodeLink>[]
    usedIn: NodeConnection<NodeItem, NodeLink>[]
  } | null>(null)
  const [notFound, setNotFound] = useState(false)
  const [user] = useContext(User)

  useEffect(() => {
    setItemInfo(null)
    setNotFound(false)
    ;(async () => {
      const [inbound, outbound] = await api.safeDo(
        () => Promise.all([api.getConnectionsTo(key, {}, true), api.getConnectionsFrom(key, {})]),
        {onError: () => setNotFound(true)},
      )
      const connection = inbound.find((connection) => connection && connection.node._key === key)
      if (!connection) {
        setNotFound(true)
      } else {
        setItemInfo({
          node: connection.node,
          related: inbound.filter((connection) => connection.node._key !== key),
          usedIn: outbound,
        })
      }
    })()
  }, [key, user])

  const notFoundScreen = () => (
    <div style={{margin: '1rem'}}>
      <Typography gutterBottom variant="h5">
        Item does not exist.
      </Typography>
      <Link to="/">Back Home</Link>
    </div>
  )

  const [navigationOpen, setNavigationOpen] = useState(false)

  useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === ' ') setNavigationOpen((b) => !b)
    }
    window.addEventListener('keydown', cb)
    return () => {
      window.removeEventListener('keydown', cb)
    }
  }, [])

  return (
    <div>
      <Navigation onClose={() => setNavigationOpen(false)} open={navigationOpen} />

      {notFound ? (
        notFoundScreen()
      ) : itemInfo ? (
        <NodePage
          node={itemInfo.node}
          setNode={(value) =>
            setItemInfo((i) => ({...i!, node: typeof value === 'function' ? (value as any)(i!.node) : value}))
          }
          relatedNodes={itemInfo.related}
          usedIn={itemInfo.usedIn}
          updateLink={(link) =>
            setItemInfo((i) => ({
              ...i!,
              related: i!.related.map((c) => (c.link._key === link._key ? {...c, link} : c)),
            }))
          }
          addUsedIn={(connection: NodeConnection<NodeItem, NodeLink>) =>
            setItemInfo((i) => ({...i!, usedIn: [...i!.usedIn, connection]}))
          }
          addConnection={(connection: NodeConnection<NodeItem, NodeLink>) =>
            setItemInfo((i) => ({...i!, related: [...i!.related, connection]}))
          }
          removeConnection={(rem: NodeConnection<NodeItem, NodeLink>) =>
            setItemInfo((i) => ({...i!, related: i!.related.filter((c) => c.link._key !== rem.link._key)}))
          }
        />
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

const nameInitials = (name: string) =>
  name
    .split(' ')
    .filter((p) => !!p)
    .map((p) => p[0].toUpperCase())
    .join('')

type NodePageProps = {
  removeConnection: (c: NodeConnection<NodeItem, NodeLink>) => void
  addConnection: (c: NodeConnection<NodeItem, NodeLink>) => void
  addUsedIn: (c: NodeConnection<NodeItem, NodeLink>) => void
  updateLink: (link: NodeLink) => void
  setNode: React.Dispatch<React.SetStateAction<NodeItem>>
  node: NodeItem
  relatedNodes: NodeConnection<NodeItem, NodeLink>[]
  usedIn: NodeConnection<NodeItem, NodeLink>[]
}

const NodePage: React.FC<NodePageProps> = ({
  node,
  setNode,
  relatedNodes,
  updateLink,
  removeConnection,
  addConnection,
  addUsedIn,
  usedIn,
}) => {
  const classes = useStyles()
  const [overflowMenuAnchor, setOverflowMenuAnchor] = useState<HTMLElement | null>()

  const groups = groupBy(relatedNodes, (item) => groupKeyForLink(item.link))
  const history = useHistory()
  const [user, setUser] = useContext(User)

  const deleteNode = async () => {
    try {
      await api.deleteNode(node)
      history.push('/')
    } catch (error) {
      api.handleError(error)
    }
  }

  const toggleBookmark = async () => {
    try {
      setUser(await api.toggleBookmark(user, node))
    } catch (error) {
      api.handleError(error)
    }
  }

  const closeOverflowWith = (action: () => void) => () => {
    action()
    setOverflowMenuAnchor(null)
  }

  const addMember = async () => {
    try {
      const email = prompt('Enter user mail')
      if (!email) return
      const users = await api.getList<PersonItem>({_key: email})
      if (users.length < 1) return alert('none found for that mail')

      const role = prompt('For which role?')

      const linkData = {type: 'member_of', role: role}
      const linkRes = await api.link({from: users[0], to: node, link: linkData})
      addConnection({node: users[0], link: {...linkData, ...linkRes}})
    } catch (error) {
      api.handleError(error)
    }
  }

  const [isEditing, setIsEditing] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const _saveNode = (node: NodeItem) =>
    api
      .safeDo(() => api.updateNode(node), {
        pre: () => setLoading(true),
        post: () => setLoading(false),
      })
      .then(() => setHasUnsavedChanges(false))

  const saveNode = useCallback(() => {
    return _saveNode(node)
  }, [node])

  const connectButton = useRef<any>()
  useShortcut('A', () => connectButton.current?.click())
  useShortcut('e', () => setIsEditing((e) => !e))

  const saveChangesRef = useRef<any>(null)
  useEffect(() => {
    saveChangesRef.current = hasUnsavedChanges ? saveNode : null
  }, [hasUnsavedChanges, saveNode])
  useShortcut('s', () => saveChangesRef.current && saveChangesRef.current())

  const togglePrivate = () => {
    setNode((node) => ({...node, private: !node.private}))
    _saveNode({...node, private: !node.private})
  }

  const container = (fullWidth: boolean, children: JSX.Element) =>
    fullWidth ? (
      <div style={{paddingLeft: '24px', paddingRight: '24px', marginTop: '1rem'}}>{children}</div>
    ) : (
      <Container style={{marginTop: '1rem'}}>{children}</Container>
    )

  const isBookmarked = user.bookmarks.includes(node._key)

  return (
    <div>
      <div className={classes.floatingFooter}>
        {hasUnsavedChanges && (
          <Button disabled={loading} color="inherit" className={classes.floatingButtonText} onClick={saveNode}>
            Save Changes
          </Button>
        )}
        {node.owner === user._key && (
          <FormControlLabel
            control={<Switch checked={!node.private} onChange={togglePrivate} />}
            label={node.private ? 'Private' : 'Public'}
          />
        )}
        <ConnectButton
          button={(onClick) => (
            <Button
              ref={connectButton}
              color="inherit"
              className={classes.floatingButtonText}
              onClick={(e) => onClick(e.currentTarget)}
            >
              <FontAwesomeIcon icon={faLink} />
              &nbsp;Add Link (CTLR+SHIFT+A)
            </Button>
          )}
          node={node}
          isTarget
          onCreate={addConnection}
        />
        <div
          onClick={() => setIsEditing(true)}
          className={clsx(classes.floatingFooterModeButton, isEditing && classes.floatingFooterModeButtonActive)}
        >
          <FontAwesomeIcon icon={faEdit} /> EDIT{!isEditing ? ' (CTLR+E)' : ''}
        </div>
        <div
          onClick={() => setIsEditing(false)}
          className={clsx(classes.floatingFooterModeButton, !isEditing && classes.floatingFooterModeButtonActive)}
        >
          <FontAwesomeIcon icon={faEye} /> VIEW{isEditing ? ' (CTLR+E)' : ''}
        </div>
      </div>

      {container(
        !!templateForNode(node).fullWidthPage,
        <Grid container direction="column" spacing={3}>
          <Grid item style={{display: 'flex'}}>
            {isEditing ? (
              <TextField
                value={node.title}
                label="Title"
                onChange={(e) => {
                  let text = e.target.value
                  setNode((n) => ({...n, title: text}))
                  setHasUnsavedChanges(true)
                }}
                style={{flexGrow: 1}}
                InputProps={{style: {fontSize: '2.5rem'}}}
              />
            ) : (
              <Typography variant="h3">{node.title}</Typography>
            )}

            <div style={{flexGrow: 1}}></div>

            <IconButton onClick={toggleBookmark}>{isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}</IconButton>
            <IconButton onClick={(e) => setOverflowMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={overflowMenuAnchor}
              keepMounted
              onClose={() => setOverflowMenuAnchor(null)}
              open={Boolean(overflowMenuAnchor)}
            >
              <MenuItem onClick={closeOverflowWith(deleteNode)}>Delete</MenuItem>
            </Menu>
          </Grid>

          <Grid item>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary">
                  Created by <Link to={'/item/' + node.owner}>{node.owner}</Link> <TimeAgo date={node.created_at} />
                  {/* TODO once we have revisions node.updated_at && <>last updated <TimeAgo date={node.updated_at} /></>*/}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    {relatedNodes
                      .filter((connection) => connection.link.type === 'member_of')
                      .map((connection) => (
                        <MemberOfItem onDelete={removeConnection} key={connection.link._key} connection={connection} />
                      ))}
                    <IconButton onClick={(_) => addMember()} title="Add Member">
                      <FontAwesomeIcon size="xs" icon={faUserPlus} />
                    </IconButton>
                  </Grid>

                  <Grid item>
                    {usedIn.map((connection) => (
                      <Button
                        onClick={(_) => history.push(`/item/${connection.node._key}`)}
                        key={connection.link._key}
                        className={classes.usedInButton + ' no-uppercase'}
                        variant="outlined"
                      >
                        <FontAwesomeIcon
                          className={classes.leftIcon}
                          icon={iconForNode(connection.node) || faQuestionCircle}
                        />
                        <span className="no-bold">{labelForLink(connection.link)}</span>&nbsp;
                        <span style={{textTransform: 'uppercase'}}>{connection.node.title}</span>
                      </Button>
                    ))}
                    <ConnectButton
                      node={node}
                      onCreate={addUsedIn}
                      button={(onClick) => (
                        <Button
                          color="secondary"
                          onClick={(e) => onClick(e.currentTarget)}
                          className={classes.usedInButton + ' no-uppercase'}
                          variant="outlined"
                        >
                          <FontAwesomeIcon className={classes.leftIcon} icon={faProjectDiagram} /> Link to ...
                        </Button>
                      )}
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item>
            {Object.entries(groups)
              .filter(([link, _]) => link !== 'member_of')
              .map(([link, group]: [string, NodeConnection<NodeItem, NodeLink>[]]) => (
                <div key={link} className="content-category">
                  <Typography variant="overline" gutterBottom>
                    {
                      ifVal(
                        linkTemplateForKey(link),
                        (template) => template.passiveLabel.replace('{}', '...'),
                        () => link,
                      )!
                    }
                  </Typography>
                  <div className="content-category-list">
                    {group.map((connection) =>
                      renderItemForNode({
                        ...connection,
                        onUpdateLink: updateLink,
                        onDelete: removeConnection,
                        key: connection.link ? connection.link._id : connection.node._id,
                      }),
                    )}
                  </div>
                </div>
              ))}
          </Grid>
        </Grid>,
      )}

      {renderPageForNode({node, relatedNodes, setNode, isEditing, notifyChange: () => setHasUnsavedChanges(true)})}
    </div>
  )
}

const MemberOfItem: React.FC<{
  connection: NodeConnection<NodeItem, NodeLink>
  onDelete: (connection: NodeConnection<NodeItem, NodeLink>) => void
}> = ({connection, onDelete}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const classes = useStyles()

  const displayRole = (connection: NodeConnection<NodeItem, NodeLink>) =>
    (connection.link as MemberOfLink).role ? `(${(connection.link as MemberOfLink).role})` : ''

  const closeThenDo = (action: () => void) => () => {
    setAnchorEl(null)
    action()
  }

  const unlink = async () => {
    try {
      await api.deleteLink(connection.link)
      onDelete(connection)
    } catch (error) {
      api.handleError(error)
    }
  }

  const changeRole = async () => {
    const role = prompt('New Role?')
    if (!role) return
    try {
      // eslint-disable-next-line no-extra-semi
      ;(connection.link as MemberOfLink).role = role
      await api.updateLink(connection.link, ['role'])
    } catch (error) {
      api.handleError(error)
    }
  }

  return (
    <>
      <Button variant="text" className="no-uppercase no-bold" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar className={classes.avatar}>{nameInitials(connection.node.title)}</Avatar>
        {connection.node.title} {displayRole(connection)}
      </Button>

      <Menu
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={closeThenDo(changeRole)}>Change Role</MenuItem>
        <MenuItem onClick={closeThenDo(unlink)}>Unlink</MenuItem>
      </Menu>
    </>
  )
}
