import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faQuestion} from '@fortawesome/free-solid-svg-icons'
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js'

import {ContentItemProps} from './ContentItem'
import agendaTemplate from './data/agenda'
import collectionTemplate from './data/collection'
import customerTemplate from './data/customer'
import googleDocTemplate from './data/google_doc'
import googleSheetTemplate from './data/google_sheet'
import googleSlidesTemplate from './data/google_slides'
import imageTemplate from './data/image'
import {LinkTemplate, linkTemplates} from './data/links'
import methodTemplate from './data/method'
import miroTemplate from './data/miro'
import missingInfoTemplate from './data/missing_info'
import notionTemplate from './data/notion'
import personTemplate, {isPersonItem} from './data/person'
import projectTemplate from './data/project'
import quoteTemplate from './data/quote'
import slideTemplateTemplate from './data/slide_template'
import tacitKnowledgeTemplate from './data/tacit_knowledge'
import textTemplate from './data/text'
import toolTemplate from './data/tool'
import videoTemplate from './data/video'
import {ifVal} from './utils'

export interface UnsavedNodeItem {
  title: string
  type: string
  owner: string
  groups?: string[]
  private?: boolean
  [key: string]: any
}

export interface NodeItem extends UnsavedNodeItem {
  _id: string
  _key: string
}
export interface NodeLink {
  _id: string
  _key: string
  _from: string
  _to: string
  owner: string
  groups?: string[]
  type: string
  [key: string]: any
}
export interface NodeConnection<T extends NodeItem, S extends NodeLink> {
  node: T
  link: S
}

export interface TemplateField {
  label: string
  key: string
  type: string
  hidden?: boolean
  multiline?: boolean
  array?: boolean
  min?: number
  max?: number
  rich?: boolean
  linkReversed?: boolean
}

export enum ToolAccessStatus {
  granted = 'granted',
  requested = 'requested',
  none = 'none',
}

export interface ToolAccessLink extends NodeLink {
  status: ToolAccessStatus
  roles: string[]
  notes: string
}

export interface MemberOfLink extends NodeLink {
  role?: string
}

export type RenderItemProps<T extends NodeItem> = ContentItemProps<T> & {key: string}
export type RenderPageProps<T extends NodeItem> = {
  node: T
  isEditing: boolean
  notifyChange: (field?: TemplateField) => void
  setNode: React.Dispatch<React.SetStateAction<NodeItem>>
  relatedNodes: NodeConnection<NodeItem, NodeLink>[]
  fullWidth?: boolean
}
export interface Template<T extends NodeItem> {
  type: string
  title: string
  label: string
  icon: IconDefinition
  fields: TemplateField[]
  fullWidthPage?: boolean
  copyContent?: (node: NodeItem) => Promise<void>
  parseUrl?: (url: string) => Promise<{[field: string]: any}> | null
  renderItem: (props: RenderItemProps<T>) => JSX.Element
  renderPage: (props: RenderPageProps<T>) => JSX.Element
  beforeCreate?: (item: {[id: string]: any}) => Promise<void>
}

// FIXME: can't use NodeItem instead of any. TS expects all Template<T> to have the exact fields of T
export const templates: {[key: string]: Template<any>} = {
  [slideTemplateTemplate.type]: slideTemplateTemplate,
  [customerTemplate.type]: customerTemplate,
  [tacitKnowledgeTemplate.type]: tacitKnowledgeTemplate,
  [personTemplate.type]: personTemplate,
  [quoteTemplate.type]: quoteTemplate,
  [collectionTemplate.type]: collectionTemplate,
  [methodTemplate.type]: methodTemplate,
  [videoTemplate.type]: videoTemplate,
  [imageTemplate.type]: imageTemplate,
  [missingInfoTemplate.type]: missingInfoTemplate,
  [googleSlidesTemplate.type]: googleSlidesTemplate,
  [googleSheetTemplate.type]: googleSheetTemplate,
  [googleDocTemplate.type]: googleDocTemplate,
  [textTemplate.type]: textTemplate,
  [notionTemplate.type]: notionTemplate,
  [miroTemplate.type]: miroTemplate,
  [projectTemplate.type]: projectTemplate,
  [agendaTemplate.type]: agendaTemplate,
  [toolTemplate.type]: toolTemplate,
}

export const fieldOfTemplateType = (type: string, fieldKey: string) =>
  templateForType(type).fields.find((f) => f.key === fieldKey)!
export const templateForType = (type: string) => templates[type]
export const templateForNode = (node: UnsavedNodeItem) => templateForType(node.type)
export const renderItemForNode = <T extends NodeItem>(props: RenderItemProps<T>) =>
  templateForNode(props.node).renderItem(props)
export const renderPageForNode = <T extends NodeItem>(props: RenderPageProps<T>) =>
  templateForNode(props.node).renderPage(props)
export const iconForNode = (item: NodeItem): IconDefinition | null => ifVal(templates[item.type], (i) => i.icon)
export const linkTemplateForKey = (key: string): LinkTemplate => linkTemplates.find((template) => template.key === key)!
export const labelForLink = (link: NodeLink): string => linkTemplateForKey(link.type).label
export const labelForLinkType = (type: string): string =>
  ifVal(
    linkTemplateForKey(type),
    (l) => l.label,
    () => 'Unknown',
  )!
export const passiveLabelForLinkType = (type: string, title: string): string =>
  ifVal(
    linkTemplateForKey(type),
    (l) => l.passiveLabel.replace('{}', title),
    () => 'Unknown',
  )!
export const iconForLink = (link: NodeLink): IconDefinition => iconForLinkType(link.type)
export const iconForLinkType = (type: string): IconDefinition =>
  ifVal(
    linkTemplateForKey(type),
    (l) => l.icon,
    () => faQuestion,
  )!
export const groupKeyForLink = (link: NodeLink): string =>
  ifVal(
    linkTemplateForKey(link.type),
    (l) => l.groupKey || link.type,
    () => link.type,
  )!
export const mockCreate = <T extends NodeItem>(type: string, override: {[key: string]: any} = {}): T =>
  ({
    ...templateForType(type).fields.reduce((obj, field) => ({...obj, [field.key]: defaultValueForField(field)}), {}),
    type,
    owner: 'test@4ed1.com',
    title: 'testtitle',
    _id: 'testid',
    _key: 'testkey',
    ...override,
  } as T)

/**
 * Create a value that acts as a default for this template field
 * @param field field template to create a value for
 */
export const defaultValueForField = (field: TemplateField, inArray: boolean = false) => {
  if (field.array && !inArray) return []

  switch (field.type) {
    case 'string':
      return ''
    case 'richtext':
      return EditorState.createEmpty()
    case 'agenda':
      return {
        colors: [
          {label: 'Plenum', color: '#999999'},
          {label: 'Teams', color: '#cccccc'},
          {label: 'Essen/Pausen', color: '#000000'},
        ],
        columnLabels: ['Titel', 'Inhalt/Vorgehen', 'Setup/Templates', 'Ziel'],
        rows: [],
        start: 8 * 60 + 30,
      }
    case 'range':
      return [0, field.max ? Math.floor(field.max / 2) : 10]
    case 'color':
      return '#000000'
    default:
      return null
  }
}

/**
 * Returns a nodeitem to be ready for use in the application
 *
 * @param item the nodeitem to mutate
 */
export const deserializeNodeItem = <T extends UnsavedNodeItem>(item: T): T => {
  const template = templateForNode(item)
  if (!template) console.log(item)
  const ret: any = {...item}

  if (isPersonItem(item)) ret.bookmarks = item.bookmarks ?? []

  for (const field of template.fields) {
    switch (field.type) {
      case 'richtext':
        ret[field.key] = item[field.key]
          ? EditorState.createWithContent(convertFromRaw(item[field.key]))
          : EditorState.createEmpty()
        break
      default:
        break
    }
  }
  return ret
}

/**
 * Returns a nodeitem to be ready for saving in the database
 *
 * @param item the nodeitem to mutate
 */
export const serializeNodeItem = <T extends UnsavedNodeItem>(item: T): T => {
  const template = templateForNode(item)
  const ret: any = {...item}

  for (const field of template.fields) {
    switch (field.type) {
      case 'richtext':
        ret[field.key] = convertToRaw((item[field.key] as EditorState).getCurrentContent())
        break
      default:
        break
    }
  }
  return ret
}
