import {faImage} from '@fortawesome/free-solid-svg-icons'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import React from 'react'

import {ContentItemWithPicture} from '../ContentItem'
import {NodeItem, Template} from '../data'

export interface ImageItem extends NodeItem {
  url: string
  details: string
}

const renderImageItem: Template<ImageItem>['renderItem'] = (props) => (
  <ContentItemWithPicture picture={(props.node as ImageItem).url} {...props}>
    <Typography variant="subtitle2">{props.node.title}</Typography>
    <Typography color="textSecondary" variant="body2">
      {(props.node as ImageItem).details}
    </Typography>
  </ContentItemWithPicture>
)
const renderImagePage: Template<ImageItem>['renderPage'] = ({node}) => (
  <Container>
    <Typography gutterBottom variant="h4">
      {node.title}
    </Typography>
    <Typography gutterBottom color="textSecondary">
      {(node as ImageItem).details}
    </Typography>
    <img alt={node.title} style={{maxWidth: '100%'}} src={(node as ImageItem).url} />
  </Container>
)

const imageTemplate: Template<ImageItem> = {
  label: 'Image',
  type: 'image',
  title: 'Add Image',
  icon: faImage,
  renderItem: renderImageItem,
  renderPage: renderImagePage,
  parseUrl: (url) => (url.match(/jpe?g|png|gif$/i) ? Promise.resolve({url}) : null),
  fields: [
    {label: 'Title', key: 'title', type: 'string'},
    {label: 'Details', key: 'details', type: 'string', multiline: true},
    {label: 'URL', key: 'url', type: 'file'},
  ],
}
export default imageTemplate

export interface ImageItem extends NodeItem {
  details: string
  url: string
}
