import {useEffect} from 'react'
import {Observable} from 'rxjs'
import {debounceTime, distinctUntilChanged, mapTo, skip, tap} from 'rxjs/operators'
import {useEventCallback} from 'rxjs-hooks'

export const useBufferedSave = <T extends {}>(newValue: T, save: (value: T) => void, bufferTime = 800) => {
  const [addInput] = useEventCallback<T>((event$: Observable<T>) =>
    event$.pipe(
      skip(1),
      debounceTime(bufferTime),
      distinctUntilChanged(),
      tap((value) => save(value)),
      mapTo(void 0),
    ),
  )

  useEffect(() => {
    addInput(newValue)
  }, [newValue, addInput])
}
